








































import BadgeMixin from "../../mixins/badge/badge";
import BadgeCard from "../../components/badge/BadgeCard.vue";
import { namespace } from "vuex-class";
import LocalDialog from "../../components/globals/LocalDialog.vue";
import ShowActions from "../../components/badge/ShowActions.vue";
import BadgeIdentityCard from "../../components/badge/BadgeIdentityCard.vue";
import { Component,  } from "vue-property-decorator";
import { BadgeActionMixin } from "../../mixins/badge/badge_action";
import { mixins } from "vue-class-component";
import BadgeReportCard from "../../components/badge/BadgeReportCard.vue";
import { PermanentBadge } from '../../models/badge/PermanentBadge';
import { TemporaryBadge } from '../../models/badge/TemporaryBadge';
import { VehicleBadge } from '../../models/badge/VehicleBadge';
import BadgeInterface from '../../interfaces/BadgeInterface';
import UserService from "../../services/user_service";
import { CACHE_NAME, FAKE_ENDPOINT, TOKEN_KEY } from "../../../common/config/entrypoint";
const badge = namespace("Badge");
const report = namespace("ReportModule");
const session = namespace("Session");

@Component({
  components: { BadgeReportCard, BadgeIdentityCard, ShowActions, BadgeCard, LocalDialog }
})
export default class BadgeShow extends mixins(BadgeMixin, BadgeActionMixin) {
  private userService: UserService = new UserService();
  private deferredPrompt: any = null;
  private installable: boolean = true;
  private manifestJson: any = {
    transpileDependencies: [
      'vuetify',
    ],
    publicPath: process.env.VUE_PUBLIC_PATH,
    pwa: {
      name: 'Pass\'Port',
      short_name: 'Pass\'Port',
      "description": "Pass'Port Progressive Web App",
      display: "fullscreen",
      start_url: "/index.html",
      icons: [
        {
          'src': 'logo_port_192.png',
          'sizes': '192x192',
          'type': 'image/png',
        },
        {
          'src': 'logo_port_512.png',
          'sizes': '512x512',
          'type': 'image/png',
        },
      ],
      themeColor: '#4DBA87',
      msTileColor: '#000000',
      appleMobileWebAppCapable: 'yes',
      appleMobileWebAppStatusBarStyle: 'black',
      // configure the workbox plugin
      workboxPluginMode: 'InjectManifest',
      workboxOptions: {
        // swSrc is required in InjectManifest mode.
        swSrc: 'service-worker.js',
        // ...other Workbox options...
      }
    }
  };

  @badge.State
  badge!: BadgeInterface | null;

  @badge.Mutation
  setBadge!: (badge: BadgeInterface | null) => void;

  beforeMount() {
    window.addEventListener('beforeinstallprompt', (e) => {
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault();
      // Stash the event so it can be triggered later.
      this.deferredPrompt = e;
      // Update UI notify the user they can install the PWA
      // showInstallPromotion();
    });
  }

  get computedBadge(): BadgeInterface | null {
    return this.badge;
  }

  mounted() {
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    if (isSafari) {
      this.manifestJson["pwa"]["start_url"] = this.$route.path;
      const stringManifest = JSON.stringify(this.manifestJson);
      const blob = new Blob([stringManifest], {type: 'application/json'});
      const manifestURL = URL.createObjectURL(blob);
      document.querySelector('link[rel="manifest"]')?.setAttribute('href', manifestURL);
    }

    this.updateBadge();
  }

  handleInstall() {
    this.installable = false;
    // Show the install prompt
    this.deferredPrompt.prompt();
    // Wait for the user to respond to the prompt
    this.deferredPrompt.userChoice.then((choiceResult: any) => {
      if (choiceResult.outcome === 'accepted') {
        console.log('User accepted the install prompt');
      } else {
        console.log('User dismissed the install prompt');
      }
    });
  }

  async saveToken(token: string) {
    try {
      let cache = await caches.open(CACHE_NAME);
      const responseBody = JSON.stringify({
        [TOKEN_KEY]: token
      });

      const response = new Response(responseBody);
      await cache.put(FAKE_ENDPOINT, response);

      console.log("Token saved!");
    } catch (error) {
      // It's up to you how you resolve the error
      console.log("saveToken error:", { error });
    }
  }

  async updateBadge() {
    const response = await this.badgeApi.getByToken(this.$route.params.token)

    // const isSafari = navigator.userAgent.indexOf("Safari") > -1;

    // if (isSafari) {
    //   this.saveToken(this.$route.params.token);
    // } else {
      this.userService.setToken(this.$route.params.token);
    // }

    const responseBadge = await response.json();

    if (responseBadge["@type"] === "PermanentBadge") {
      this.setBadge(new PermanentBadge(responseBadge));
    }

    if (responseBadge["@type"] === "TemporaryBadge" || responseBadge["@type"] === "VisitorBadge") {
      this.setBadge(new TemporaryBadge(responseBadge));
    }

    if (responseBadge["@type"] === "VehicleBadge") {
      this.setBadge(new VehicleBadge(responseBadge));
    }
  }
}







import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import ToastInterface from "../../interfaces/ToastInterface";
import Toast from "../../models/Toast";

const toast = namespace('Toast');
@Component({
    components: {}
})
export default class ToastContainer extends Vue {
    @toast.State
    public toasts!: Array<ToastInterface>;

    @toast.Mutation
    public clear!: () => void;


    get hasAlert() {
        if (this.toasts.length === 0) {
            return false;
        }

        this.toasts.map((toast: Toast) => this.handleAlert(toast));

        this.clear();
        return this.toasts.length;
    }

    handleAlert(toast: Toast) {
        this.$toasted.global[`app_${toast.type}`](
            { message: this.$i18n.t(toast.message)},
            toast
        );
    }
}

import AbstractModel from "./AbstractModel";
import { Country } from "./Country";
import { OrganizationInterface } from "../interfaces/OrganizationInterface";
import Person from "./Person";
import Media from "./Media";
import { EnumerationInterface } from '../interfaces/EnumerationInterface';

export class Organization extends AbstractModel implements OrganizationInterface {
    "@type": string;
    acronym?: string;
    actions?: Array<string>;
    badgeCount?: number;
    code?: string;
    comment?: string;
    country?: Country;
    currentPartnership?: string;
    documents: Array<Media> = [];
    email?: string;
    fax?: null;
    hasBadgeToPay?: boolean
    identificationNumber?: string;
    legalName?: string;
    manager?: Person;
    mostRecentPartnership?: Organization;
    postalAddress?: {
        complement?: string,
        route?: string,
        postalCode?: string,
        locality?: string,
        country?: Country
    };
    partnership?: Organization;
    partnerships?: Array<Organization>;
    siret?: string;
    state?: string;
    telephone?: string;
    tva?: string;
    type: EnumerationInterface;

    constructor(data: OrganizationInterface) {
        super(data);
        this["@type"] = data["@type"];
        this.documents = data.documents;
        this.type = data.type;
        // @ts-ignore
        Object.keys(data).map((key: string) => Object.assign(this, { [key]: data[key] }));
    }
}

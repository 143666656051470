import Landing from "../views/Landing.vue";
import Unauthorized from "../views/Unauthorized.vue";
import Layout from "../components/globals/Layout.vue";

export default {
    path: '/',
    component: Layout ,
    children: [
        {
            path: '',
            name: 'Landing',
            component: Landing
        },
        {
            path: '/unauthorized',
            name: 'Unauthorized',
            component: Unauthorized
        }
    ]
}






















































import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import BadgeInterface from "../../interfaces/BadgeInterface";
import PermanentBadgeForm from './PermanentBadgeForm.vue';
import TemporaryBadgeForm from './TemporaryBadgeForm.vue';
import VehicleBadgeForm from './VehicleBadgeForm.vue';
import VisitorBadgeForm from './VisitorBadgeForm.vue';
import PermanentBadgeFormInterface from "../../interfaces/badge/PermanentBadgeFormInterface";
import TemporaryBadgeFormInterface from "../../interfaces/badge/TemporaryBadgeFormInterface";
import VehicleBadgeFormInterface from "../../interfaces/badge/VehicleBadgeFormInterface";
import VisitorBadgeFormInterface from "../../interfaces/badge/VisitorBadgeFormInterface";
import UserService from "../../services/user_service";
import Person from "../../models/Person";
import { ROLE_ADMIN, ROLE_SECURITY } from "../../../common/config/roles";
import RestrictedBadgeEditForm from "./RestrictedBadgeEditForm.vue";
import PersonalBadgeFormInterface from "../../interfaces/badge/PersonalBadgeFormInterface";

const badge = namespace("Badge");
const session = namespace("Session");
@Component({
  components: { RestrictedBadgeEditForm, PermanentBadgeForm, TemporaryBadgeForm, VehicleBadgeForm, VisitorBadgeForm }
})
export default class BadgeEditModal extends Vue {
  private dialog: boolean = false;

  private userService: UserService = new UserService();

  @session.State
  user!: Person;

  @badge.State
  badge!: BadgeInterface;

  @badge.Action
  updatePermanent!: (form: PermanentBadgeFormInterface) => void;

  @badge.Action
  updateTemporary!: (form: TemporaryBadgeFormInterface) => void;

  @badge.Action
  updateVehicle!: (form: VehicleBadgeFormInterface) => void;

  @badge.Action
  updateVisitor!: (form: VisitorBadgeFormInterface) => void;

  isAdmin() {
    return this.userService.isGranted(this.user, [ ROLE_ADMIN, ROLE_SECURITY ]);
  }

  onUpdateRestrictedForm(form: PersonalBadgeFormInterface) {
    if (this.badge["@type"] === "PermanentBadge") {
      this.onUpdatePermanent(form as PermanentBadgeFormInterface);
    }

    if (this.badge["@type"] === "TemporaryBadge") {
      this.onUpdateTemporary(form as TemporaryBadgeFormInterface);
    }
    if (this.badge["@type"] === "VisitorBadge") {
      this.onUpdateVisitor(form as VisitorBadgeFormInterface);
    }
  }

  async onUpdatePermanent(form: PermanentBadgeFormInterface) {
    form["@id"] = this.badge["@id"];
    delete form.organization;
    await this.updatePermanent(form);
  }

  async onUpdateTemporary(form: TemporaryBadgeFormInterface) {
    form["@id"] = this.badge["@id"];
    delete form.organization;
    await this.updateTemporary(form);
  }

  async onUpdateVehicle(form: VehicleBadgeFormInterface) {
    form["@id"] = this.badge["@id"];
    delete form.organization;
    await this.updateVehicle(form);
  }

  async onUpdateVisitor(form: VisitorBadgeFormInterface) {
    form["@id"] = this.badge["@id"];
    delete form.organization;
    await this.updateVisitor(form);
  }
}

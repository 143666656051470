






import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from "vuex-class";
import Person from "../models/Person";
import { ROLE_ADMIN, ROLE_SECURITY } from "../../common/config/roles";
import Loading from "../components/globals/Loading.vue";

const session = namespace("Session");
@Component({
  components: { Loading },
})
export default class Landing extends Vue {
  @session.State
  public user!: Person | null;

  @session.Action
  public updateUser!: () => void;

  beforeMount() {
    this.userUpdated(this.user);
  }

  @Watch('user')
  userUpdated(user: Person | null) {
    if (user === null || user.roles === undefined) {
      return;
    }

    if (user.roles.indexOf(ROLE_SECURITY) > -1 || user.roles.indexOf(ROLE_ADMIN) > -1 ) {
      this.$router.push({ name: 'OrganizationList' });
      return;
    }

    if (user.organization) {
      this.$router.push({ name: 'OrganizationShow', params: { id: user.organization.uuid } });
    }
  }
}

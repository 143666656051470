import IdentifierInterface from "../interfaces/IdentiferInterface";

export default abstract class AbstractModel implements IdentifierInterface {
    "@id": string;
    uuid: string

    constructor(data: IdentifierInterface) {
        this.uuid = data.uuid;
        this.setIdentifier(data["@id"]);
    }

    setIdentifier(identifier?: string) {
        Object.assign(this, {"@id": identifier});
    }
}
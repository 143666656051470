

























import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import Language from "./Language.vue";

const session = namespace("Session");
@Component({
  components: { Language },
})
export default class Header extends Vue {
}

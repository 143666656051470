import isObject from 'lodash/isObject';
import { ACCESS_DENIED_URI, ENTRYPOINT, LOGIN_URI } from "../config/entrypoint";
import { normalize } from './hydra';
import router from "../../src/router";

const makeParamArray = (key: any, arr: any) =>
    arr.map((val: any) => `${key}[]=${val}`).join('&');

// const anonymousIds = [
//     'authentication_token',
//     'security/register',
//     'security/password/request',
//     'security/password/reset'
// ];

export default function (id: any, options: any = {}, mimeType: any = 'application/ld+json'): any {
    if ('undefined' === typeof options.headers) {
        options.headers = new Headers();
    }

    if (null === options.headers.get('Accept')) {
        options.headers.set('Accept', mimeType);
    }

    if (
        'undefined' !== options.body &&
        !(options.body instanceof FormData) &&
        null === options.headers.get('Content-Type')
    ) {
        options.headers.set('Content-Type', mimeType);
    }

    if (options.params) {
        const queryString: string = Object.keys(options.params)
            .map(key => {
                    if (key === 'order') {
                        return;
                    }

                    return Array.isArray(options.params[key])
                        ? makeParamArray(key, options.params[key])
                        : `${key}=${options.params[key]}`
                }
            )
            .join('&');

        id = `${id}?${queryString}`;

        if (options.params.order !== null && options.params.order !== undefined) {
            id = `${id}&${options.params.order}`
        }
    }

    const entryPoint: any = ENTRYPOINT + (ENTRYPOINT.endsWith('/') ? '' : '/');

    if (options.body && !(options.body instanceof FormData)) {
        const payload: any = options.body && JSON.parse(options.body);
        if (isObject(payload) && payload['@id']) {
            options.body = JSON.stringify(payload);
            // options.body = JSON.stringify(normalize(payload));
        }
    }

    const url: any = new URL(id, entryPoint);

    // @ts-ignore
    return global.fetch(url, options).then((response: any) => {
        if (response.redirected && response.url === LOGIN_URI) {
            window.location.href = LOGIN_URI

            return;
        }



        if (response.status === 403) {
            window.location.href = ACCESS_DENIED_URI;
        }

        if (response.ok) {
            return response;
        }

        return response.json().then(
            (json: any) => {
                const error =
                    json['hydra:description'] ||
                    json['hydra:title'] ||
                    'An error occurred.';

                if (!json.violations) throw Error(error);
            },
            () => {
                throw new Error(response.statusText || 'An error occurred.');
            }
        );
    });
}

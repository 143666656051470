import BadgeOrganizationInterface from "../interfaces/BadgeOrganizationInterface";
import AbstractModel from "./AbstractModel";

export default class BadgeOrganization extends AbstractModel implements BadgeOrganizationInterface {
    code: string | null;
    name: string | null;

    constructor(data: BadgeOrganizationInterface) {
        super(data);
        this.code = data.code;
        this.name = data.name;
    }
}
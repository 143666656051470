import Toasted, { ToastObject } from 'vue-toasted';
import Vue from 'vue';
import { TOAST_TYPE_ERROR, TOAST_TYPE_INFO, TOAST_TYPE_SUCCESS } from "../../src/models/Toast";

Vue.use(Toasted, {
    iconPack: 'mdi',
    theme: 'toasted-primary'
});

export const errorOptions = {
    action:  {
        text : '',
        icon: 'mdi-close',
        onClick : (e: any, toastObject: ToastObject) => {
            toastObject.goAway(0);
        }
    },
    icon: 'mdi-danger',
    message: 'toast.success',
    type: TOAST_TYPE_ERROR
};

export const successOptions = {
    duration: 2000,
    icon: 'mdi-check',
    message: 'toast.success',
    type: TOAST_TYPE_SUCCESS
};

export const infoOptions = {
    duration: 3000,
    icon: 'mdi-info',
    message: 'toast.info',
    type: TOAST_TYPE_INFO
};

Vue.toasted.register('app_default',
    (payload) => {
        return payload.message;
    },
    errorOptions
);

Vue.toasted.register('app_success',
    (payload) => {
        return payload.message;
    },
    successOptions
)

Vue.toasted.register('app_info',
    (payload) => {
        return payload.message;
    },
    infoOptions
)








import { Component, Vue } from 'vue-property-decorator';
import { namespace } from "vuex-class";

const session = namespace("Session");
@Component({})
export default class Unauthorized extends Vue {
}

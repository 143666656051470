







































































































































































































import { FormMixin } from '../../mixins/form';
import PermanentBadgeFormInterface from "../../interfaces/badge/PermanentBadgeFormInterface";
import Vehicle from "../../models/vehicle/Vehicle";
import { Component, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";
import Person from "../../models/Person";
import { ROLE_ADMIN, ROLE_SECURITY } from "../../../common/config/roles";
import Avatar from "../globals/Avatar.vue"
import Phone from "../../components/globals/Phone.vue";
import PermanentBadgeInterface from "../../interfaces/badge/PermanentBadgeInterface";
import { VForm } from '../../models/form/VForm';

const badge = namespace("Badge");
const session = namespace("Session");
@Component({
  components: { Avatar, Phone },
})
export default class PermanentBadgeForm extends FormMixin {
  private valid: boolean = false;
  private formDefault: PermanentBadgeFormInterface =  {
    avatar: null,
    email: null,
    effectiveDate: null,
    familyName: null,
    givenName: null,
    identityDocument: null,
    jobTitle: null,
    language: 'FR',
    organization: `/api/organizations/${this.$route.params.id}`,
    publicService: false,
    telephone: null,
    vehicles: []
  };
  private form: PermanentBadgeFormInterface = { ...this.formDefault };

  @badge.State
  badge!: PermanentBadgeInterface;

  @session.State
  user!: Person;

  addVehicle() {
    this.form.vehicles.push(new Vehicle({
      color: null,
      model: null,
      registration: null,
      type: null
    }));
  }

  isAdmin() {
    if (!this.user || this.user.roles === undefined) {
      return false;
    }
    return this.user.roles.indexOf(ROLE_ADMIN) > -1 || this.user.roles.indexOf(ROLE_SECURITY)
  }

  beforeMount() {
    if (this.badge) {
      this.form.email = this.badge.email;
      this.form.effectiveDate = this.badge.effectiveDate;
      this.form.familyName = this.badge.familyName;
      this.form.givenName = this.badge.givenName;
      this.form.jobTitle = this.badge.jobTitle;
      this.form.language = this.badge.language;
      this.form.publicService = this.badge.publicService;
      this.form.telephone = this.badge.telephone;
      this.form.vehicles = this.badge.vehicles;
    }
  }

  onAvatarUpdate(file: Blob) {
    this.form.avatar = file;
  }

  removeVehicle(index: number) {
    this.form.vehicles.splice(index, 1);
  }

  submit() {
    this.valid = (this.$refs.permanentForm as VForm).validate();

    if (this.valid) {
      this.$emit(this.badge ? 'update' : 'submit', this.form);
      this.form = { ...this.formDefault };
      console.log(this.formDefault);
    }
  }
}

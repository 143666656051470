







































import { FormMixin } from "../../mixins/form";
import { VForm } from "../../models/form/VForm";
import { Component, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";
import PersonalBadgeInterface from "../../interfaces/badge/PersonalBadgeInterface";

const badge = namespace("Badge");
const session = namespace('Session');
@Component({
  components: {}
})
export default class RestrictedBadgeEditForm extends FormMixin {
  private valid: boolean = false;

  @badge.State
  badge!: PersonalBadgeInterface;

  submit() {
    this.valid = (this.$refs.form as VForm).validate();

    if (this.valid) {
      this.$emit('submit', this.badge);
    }
  }

}

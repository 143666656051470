












import VuePhoneNumberInput from 'vue-phone-number-input';
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: { VuePhoneNumberInput }
})
export default class Phone extends Vue {
  @Prop()
  private error!: boolean;
  @Prop()
  private labelProp!: string;
  @Prop()
  private requiredProp!: boolean;
  @Prop()
  private valueProp!: string;

  private data: string = '';
  private label: string = this.$t('vue_phone_number.telephoneNumberLabel') as string;
  private required: boolean = false;
  private validation: boolean = false;

  get valid() {
    return this.validation;
  }

  private translations: Object = {
    countrySelectorLabel: this.$t('vue_phone_number.countrySelectorLabel'),
    countrySelectorError: this.$t('vue_phone_number.countrySelectorError'),
    phoneNumberLabel: this.labelProp,
    example: this.$t('vue_phone_number.example')
  }

  beforeMount() {
    this.data = this.valueProp;
    this.label = this.labelProp;
    this.required = this.requiredProp;
    this.validation = this.error;
  }

  onUpdate(data: any) {
    this.validation = (this.requiredProp === true && data.formattedNumber !== '' && data.formattedNumber !== undefined) ||
      this.requiredProp === false;
    this.$emit('update', data.formattedNumber);
  }
}

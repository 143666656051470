export const messagesFr = {
    "toast": {
        "close": "fermer",
        "error": "Une erreur est survenue.",
        "info": "Info",
        "success": "Opération effectuée avec succès."
    },
    "pwa": {
        "download": "Télécharger l'application",
        "help1": "Pour installer l'application :",
        "help2": "Options navigateur > Ajouter à l'écran d'accueil "
    },
    "actions": {
        "actions": "Actions",
        "addFile": "Joindre un document",
        "cancel": "Annuler",
        "submit": "Enregistrer",
        "edit": "Modifier",
        "create": "Créer",
        "continue": "Continuer"
    },
    "admin": {
        "badgeOrganization": "Organisations badges",
        "gpmd": "Utilisateurs GPMD/LANCRY",
        "person": "Utilisateurs",
        "price": "Prix badges",
        "trustedOrganization": "Société résidente",
        "shipperOrganization": "Société externe / Transporteur",
        "seamanOrganization": "Société externe / Marinier",
        "providerOrganization": "Société externe / Autres"
    },
    "badge": {
        "actions": {
            "update": "Modifier le badge",
            "print": "Imprimer le badge",
            "renew": "Renouveler le badge",
            "report": "Signaler un incident",
            "disable": "Désactiver le badge",
            "delete": "Supprimer le badge",
            "preorder": "Précommander",
            "topay": "À payer"
        },
        "backToList": "Retour à la liste des badges",
        "massActions": {
            "renewal": "Renouveler les badges sélectionnés",
            "disable": "Désactiver les badges sélectionnés",
            "print": "Imprimer les badges sélectionnés",
            "export": "Exporter les badges sélectionnés",
        },
        "addVehicle": "Ajouter un véhicule",
        "card": {
            "partner": "Société partenaire",
            "send": "Envoyer le badge",
            "validity": "Validité",
            "from": "De",
            "to": "Au"
        },
        "chooseType": "Choisissez un type de badge",
        "create": "Nouvelle demande de badge",
        "confirmActionDisable": "Vous allez désactiver ",
        "confirmActionPrint": "Vous allez imprimer ",
        "confirmActionRenew": "Vous allez renouveler ",
        "confirmActionContinue": "badges, voulez-vous continuer ?",
        "confirmActionState": "Vous allez changer le statut de ce badge, voulez-vous continuer ?",
        "effectiveDate": "Date de début de validité du badge",
        "endDate": "Date de fin",
        "email": "Email",
        "expiresAt": "Date d'expiration",
        "familyName": "Nom",
        "firstPermanentBadge": "Badge permanent (premier)",
        "fullName": "Identité",
        "givenName": "Prénom",
        "identity": "Joindre une pièce d'identité",
        "identityTitle": "Pièce d'identité",
        "jobTitle": "Fonction",
        "new": "Nouveau badge",
        "organizationLegalName": "Nom de l'entreprise",
        "orderNumber": "Numéro de commande",
        "permanent": "Badge permanent",
        "publicService": "Service public",
        "registration": "Immatriculation",
        "selectedCount1": "Vous avez sélectionné",
        "selectedCount2": "lignes sur",
        "selectedCount3": "cliquez ici pour dé/sélectionner toutes les autres lignes du tableau",
        "sentTitle1": "Badge envoyé par mail à",
        "sentTitle2": ", le ",
        "state": "Statut",
        "startDate": "Date de début",
        "temporary": "Badge temporaire",
        "title": {
            "global": "Liste des badges",
            "permanent": "Liste des badges permanents",
            "temporary": "Liste des badges temporaires",
            "vehicle": "Liste des badges véhicules",
            "visitor": "Liste des badges visiteurs"
        },
        "type": "Type",
        "vehicle": "Badge véhicule",
        "visitor": "Badge visiteur",
        "vehicleColor": "Couleur du véhicule",
        "vehicleModel": "Modèle du véhicule",
        "vehicleTitle": "Véhicules",
        "vehicleType": "Type de véhicule",
        "visitReason": "Motif de la visite"
    },
    "badgeOrganization": {
        "add": "Ajout d'un élément",
        "code": "Code",
        "name": "Nom"
    },
    "cart": {
        "adminPay": "Titre gracieux",
        "badgeNumber": "Nombre de badge",
        "basket": "dans le panier",
        "pay": "Payer la commande",
        "permanentCount": "badges individuels permanent - ",
        "permanentLink": "badges permanent",
        "permanentUnitPrice": "Badge individuel permanent - ",
        "totalPrice": "Prix total",
        "payText": "La vente de badges est exonérée de TVA en application de l’article 262-II-7° du Code général des impôts",
        "status": "Votre société doit d'abord être validée par un administrateur",
        "unitPrice": "Tarif unitaire",
        "vehicleCount": "badges individuels véhicule - ",
        "vehicleLink": "badges véhicule - ",
        "vehicleUnitPrice": "Badge individuel véhicule - "
    },
    "confirmation": "Confirmation",
    "exception": {
        "badgeLimit": "Vous avez dépassé la limite de badge autorisé, qui est de 6",
    },
    "guardHouse": {
        "title": "Quel poste occupez-vous ?",
        "label": "Votre poste"
    },
    "header": {
        "admin": "Admin",
        "badges": "Badges",
        "basket": "Panier en cours",
        "commands": "Commandes",
        "history": "Historique des commandes",
        "notice": "Annonces",
        "organization": "Société",
        "organizations": "Sociétés",
        "organizationUserList": "Utilisateurs",
        "partnerships": "Partenariats",
        "permanent_badges": "Badges permanents",
        "stats": "Stats",
        "temporary_badges": "Badges temporaires",
        "vehicle_badges": "Badges véhicules",
        "visitors": "Visiteurs",
        "user_edit": "Modification des informations personnelles",
        "user_account": "Informations personnelles"
    },
    "menu": {
        "myAccount": "Mon Compte",
        "logout": "Déconnexion"
    },
    "organization": {
        "accepted": "Société acceptée",
        "rejected": "Société refusée",
        "comment": "Commentaire de refus",
        "identificationNumber": "Code tiers",
        "list": "Liste des sociétés",
        "state": "Validation administrative",
        "create": "Nouvelle société",
        "organization": "Société",
        "manager": "Référent société",
        "identity_title": "Identité de la société",
        "postalCode_title": "Adresse de correspondance",
        "manager_title": "Responsable / Référent",
        "partnership_title": "Demande de partenariat",
        "legalName": "Raison sociale",
        "acronym": "Sigle",
        "country": "Pays",
        "siret": "N° de SIRET",
        "tva": "N° TVA intracommunautaire",
        "type": "Typologie",
        "telephone": "Téléphone fixe",
        "email": "Mail",
        "fax": "Fax",
        "badgeCount": "Nb de badges",
        "requiredAction": "Action requise",
        "search": "Rechercher une société",
        "success_message": "Vos informations ont bien été prises en compte. Un email de création de compte va être envoyé au responsable.",
        "success_title": "Merci !",
        "file": {
            "1": "(1) Pour la France, joindre un extrait K.BIS de la société, daté de moins de 6 mois pour toute première demande.",
            "2": "(2) Pour les mariniers joindre une photocopie de la carte de marinier."
        }
    },
    "password": {
        "new": "Nouveau mot de passe",
        "repeat": "Répéter le nouveau mot de passe",
    },
    "partnership": {
        "add": "Faire une nouvelle demande",
        "createdAt": "Date de la demande",
        "duration": "Durée",
        "askedBy": "Partneriat demandé par",
        "partnerManager": "Référent société externe",
        "trustedPartner": "Société résidente",
        "trustedPartnerManager": "Référent société résidente",
        "state": "Statut",
        "updatedAt": "Date de changement de statut",
        "badgeCount": "Nb de badges",
        "validate": "Valider le partenariat",
        "refuse": "Refuser le partenariat"
    },
    "postalCode": {
        "complement": "Complément",
        "country": "Pays",
        "postalCode": "Code postal",
        "route": "Voie",
        "locality": "Ville",
    },
    "person": {
        "add": "Ajout nouvel utilisateur",
        "familyName": "Nom",
        "givenName": "Prénom",
        "jobTitle": "Fonction",
        "telephone": "Téléphone fixe",
        "mobilePhone": "Téléphone mobile",
        "lang": "Langue",
        "email": "Mail",
        "role": "Rôle"
    },
    "price": {
        "create": "Créer une nouvelle grille de prix",
    },
    "report": {
        "add": "Signalement",
        "comment": "Commentaire",
        "delete": "Supprimer",
        "empty": "Pas de signalement",
        "title1": "Signalement du",
        "title2": "à",
        "title3": "par",
        "update": "Modifier"
    },
    "rules": {
        "usedEffectiveDate": "Une autre grille existe pour cette date",
        "usedEmail": "Cet email est déjà utilisé",
        "invalidEmail": "Cet email est invalide",
        "invalidPhone": "Format invalide exemple : +33",
        "invalidSiret": "Mauvais format",
        "invalidTva": "Mauvais format",
        "invalidPassword": "Le mot de passe doit être composé au minimum de 8 caractères dont une majuscule, une minuscule, un caractère spécial et un chiffre",
        "invalidPostalCode": "Le format n'est pas valide",
        "fileTooLarge": "Le fichier doit être inférieur à 10 Mo",
        "required": "Ce champs est requis",
        "textTooLong": "Le texte est trop long",
        "usedSiret": "Ce SIRET est déjà utilisé",
        "usedTva": "Ce numéro de TVA intracommunautaire est déjà utilisé",
        "identicalPassword": "Les mots de passe doivent être identique",
    },
    "ROLE_ADMIN": "Admin",
    'ROLE_USER': 'Utilisateur',
    "ROLE_BADGE_HOLDER": "Détenteur de badge",
    "ROLE_EXTERNAL_COMPANY": "Société externe",
    "ROLE_GATEMAN": "Gateman",
    "ROLE_VIDEOMAN": "Videoman",
    "ROLE_LIGHT_RESIDENT_COMPANY": "Société résidente légère",
    "ROLE_RESIDENT_COMPANY": "Société résidente",
    "ROLE_SECURITY": "Bureau de sûreté",
    "route": {
        "Home": "Accueil",
        "BadgeList": "Badges",
        "Organization": "Société",
        "OrganizationCreate": "Création d'une demande de badge",
        "OrganizationEdit": "Modifier Société",
        "OrganizationList": "Sociétés",
        "OrganizationPartnershipList": "Partenaires",
        "OrganizationShow": "Détail Société",
        "OrganizationUserList": "Utilisateurs",
        "OrganizationBadgeList": "Badges",
        "OrganizationBadgeListPermanent" : "Badges permanents",
        "OrganizationBadgeListVehicle" : "Badges véhicules",
        "BadgeShowPermanentBadge" : "Badges permanents",
        "BadgeShowVehicleBadge": "Badges véhicules",
        "BadgeShowTemporaryBadge": "Badges temporaires",
    },
    "supplyOrder": {
        "badgeCount": "Nb de badges",
        "createdAt": "Date de la commande",
        "invoiceNumber": "Numéro SIGF",
        "number": "N° de la commande",
        "show": "Détail de la commande",
        "state": "Statut de la commande",
        "total": "Coût total (€)"
    },
    "tooltip": {
        "badge": {
            "temporary": "Les badges temporaires peuvent avoir une validité maximale de 7 jours consécutifs et ne peux être renouvelés plus de 5 fois dans l’année pour une même entreprise (soit 5 visites autorisées)"
        }
    },
    "vue_phone_number": {
        "countrySelectorLabel": 'Code pays',
        "countrySelectorError": 'Choisir un pays',
        "telephoneNumberLabel": 'Numéro de téléphone fixe',
        "mobilePhoneNumberLabel": 'Numéro de téléphone mobile',
        "example": 'Exemple :'
    },
    "vuetify": {
        "dataFooter": {
            "itemsPerPageText": "Lignes par pages"
        }
    },
    "accepted": "Accepté",
    "new": "Nouveau",
    "refused": "Refusé",
    "PermanentBadge": "permanent",
    "VehicleBadge": "véhicule",
    "VisitorBadge": "visiteur",
    "TemporaryBadge": "temporaire",
    "login": {
        "text": "Afin de pouvoir accéder dans les ZNLAP à titre permanent (1 an), tous les accédants :\n" +
            "          Services de l'Etat, résidents (ex: installations portuaires et sociétés résidentes, situées à\n" +
            "          l'intérieur de la ZNLAP),\n" +
            "          services portuaires (ex : pilotage, remorquage, lamanage,\n" +
            "          opérateurs ferroviaires du GPMD), partenaires (ex: toutes autres sociétés en lien professionnel avec les\n" +
            "          sociétés résidentes oul GPMD\n" +
            "          (ex : prestataires de services, fournisseurs, transporteurs, sous-traitants), doivent remplir un dossier\n" +
            "          de demande de laissez-passer disponible dans cette application.",
        "badge": "Faire une première demande de badge",
        "welcome": "Bienvenue",
        "email": {
            "label": "Adresse mail",
            "placeholder": "Email"
        },
        "password": {
            "label": "Mot de passe",
            "placeholder": "Mot de passe"
        },
        "newPassword": {
            "label": {
                "first": "Nouveau mot de passe",
                "second": "Veuillez confirmer votre nouveau mot de passe",
            },
            "placeholder": {
                "first": "Mot de passe",
                "second": "Mot de passe",
            }
        },
        "actions": {
            "login": "Se connecter",
            "request": "Mot de passe oublié ?",
            "requestPassword": "Demander un nouveau mot de passe",
            "lang": "Changer de langue",
            "reset": "Modifier mon mot de passe"
        },
    }
};
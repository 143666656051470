import Badge from "../Badge";
import PersonalBadgeInterface from "../../interfaces/badge/PersonalBadgeInterface";
import MediaInterface from "../../interfaces/MediaInterface";

export default class PersonalBadge extends Badge implements PersonalBadgeInterface  {
    avatar: MediaInterface | null;
    email: string | null;
    identityDocument: Object | File | null;
    givenName: string;
    familyName: string;
    qrCode: string;
    jobTitle: string | null;
    telephone: string | null;

    constructor(data: PersonalBadgeInterface) {
        super(data);
        this.avatar = data.avatar;
        this.email = data.email;
        this.identityDocument = data.identityDocument;
        this.qrCode = data.qrCode;
        this.givenName = data.givenName;
        this.familyName = data.familyName;
        this.jobTitle = data.jobTitle;
        this.telephone = data.telephone;
    }
}
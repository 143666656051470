export default class LocalStorage {
  constructor() {}

  getItem(itemName: string): any | null
  {
    return JSON.parse(<string>window.localStorage.getItem(itemName));
  }

  saveItem(itemName: string, item: any): void
  {
    window.localStorage.setItem(itemName, JSON.stringify(item));
  }

  destroyItem(itemName: string): void
  {
    window.localStorage.removeItem(itemName);
  }
}





































































import { Component, Prop } from "vue-property-decorator";

import { FormMixin } from "../../mixins/form";
import { VForm } from "../../models/form/VForm";
import Report from "../../models/Report";
import { namespace } from "vuex-class";
import Person from "../../models/Person";

const session = namespace('Session');
@Component({
  components: {}
})
export default class ReportBadgeModal extends FormMixin {
  @Prop()
  report!: Report;

  private comment: string | null = null;
  private dialog: boolean = false;
  private valid: boolean = false;

  @session.State
  user!: Person;

  mounted() {
    if (this.report) {
      this.comment = this.report.comment;
    }
  }

  submit() {
    this.valid = (this.$refs.form as VForm).validate();

    if (!this.valid) {
      return;
    }

    const payload = this.report ?
      { author: this.report.author["@id"], comment: this.comment, "@id": this.report["@id"] } :
      { author: this.user["@id"], comment: this.comment };

    this.$emit(this.report ? 'update' : 'create', payload);

    if (!this.report) {
      this.comment = null;
    }

    this.dialog = false;
  }
}

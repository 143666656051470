import BadgeShow from "../views/badge/BadgeShow.vue";
import BadgeLayout from "../components/badge/BadgeLayout.vue";

export default {
    path: '/external/badges',
    component: BadgeLayout ,
    children: [
        {
            path: ':token',
            component: BadgeShow,
            name: 'BadgeShow'
        },
    ]
}

import { ToastAction, ToastIconPack, ToastPosition, ToastTheme, ToastType } from "vue-toasted";
import ToastInterface from "../interfaces/ToastInterface";

export default class Toast implements ToastInterface {
    position?: ToastPosition = 'top-right';
    duration?: number = 2000;
    action?: ToastAction | ToastAction[];
    fullWidth?: boolean;
    fitToScreen?: boolean;
    className?: string | string[];
    containerClass?: string | string[];
    icon?: ((ToastIcon: HTMLElement) => HTMLElement) | string | { name: string, after: boolean };
    type?: ToastType|string;
    theme?: ToastTheme|string;
    onComplete?: () => any;
    closeOnSwipe?: boolean;
    singleton?: boolean;
    iconPack?: ToastIconPack|string = 'mdi';
    message: string = 'toast.error';

    constructor(data: ToastInterface = {
        message: 'toast.error',
    }) {
        this.message = data.message;
        // @ts-ignore
        Object.keys(data).map((key: string) => Object.assign(this, { [key]: data[key] }));
    }
}

export const TOAST_TYPE_ERROR = 'default';
export const TOAST_TYPE_SUCCESS = 'success';
export const TOAST_TYPE_INFO = 'info';

export const TOAST_THEME_PRIMARY = 'toasted-primary';
export const TOAST_THEME_OUTLINED = 'outlined';
export const TOAST_THEME_BUBBLE = 'bubble';

export const TOAST_MESSAGE_ERROR = 'toast.error';
export const TOAST_MESSAGE_INFO = 'toast.info';
export const TOAST_MESSAGE_SUCCESS = 'toast.success';

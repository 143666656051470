import fetch from '../../utils/fetch';
import OrganizationStateFormInterface from "../../../src/interfaces/organization/OrganizationStateFormInterface";

export default class Api {
    endpoint: string;

    constructor(endpoint: string) {
        this.endpoint = endpoint;
    }

    find(id: string) {
        return fetch(`${id}`);
    }

    findAll(params: any) {
        return fetch(this.endpoint, params);
    }

    findAllWithoutPagination(params: any) {
        return fetch(this.endpoint + '?pagination=false', params);
    }

    create(payload: any) {
        return fetch(this.endpoint, {method: 'POST', body: JSON.stringify(payload)});
    }

    createCollection(payload: any) {
        return fetch(this.endpoint, {method: 'POST', body: JSON.stringify(payload)});
    }

    delete(item: any) {
        return fetch(item['@id'], {method: 'DELETE'});
    }

    update(payload: any) {
        return fetch(payload['@id'], {
            method: 'PUT',
            body: JSON.stringify(payload)
        });
    }
}

import OptionInterface from "../../interfaces/dataTable/OptionInterface";

export class Option implements OptionInterface {
    descending: any;
    groupBy: Array<string> = [];
    groupDesc: Array<string> = [];
    itemsPerPage: number = 10;
    multiSort: boolean = false;
    mustSort: boolean = false;
    page: number = 1;
    sortBy: Array<string> = [];
    sortDesc: Array<string> = [];
    totalItems: number | null = null;
}
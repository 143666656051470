



































































import { Component, Prop, Vue } from "vue-property-decorator";

import { namespace } from "vuex-class";
import { FormMixin } from "../../mixins/form";
import { VForm } from "../../models/form/VForm";

const badge = namespace("Badge");
const session = namespace("Session");
@Component({
  components: {}
})
export default class SendBadgeModal extends FormMixin {
  @Prop()
  email!: string;

  private to: string | null = null;
  private dialog: boolean = false
  private valid: boolean = false;

  close() {
    this.valid = (this.$refs.form as VForm).validate();

    if (!this.valid) {
      return;
    }

    this.$emit('submit', this.to);
    this.to = null;
    this.dialog = false;
  }

  mounted() {
    this.to = this.email;
  }
}

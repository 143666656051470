import Badge from "../Badge";
import VehicleInterface from "../../interfaces/vehicle/VehicleInterface";
import VehicleBadgeInterface from "../../interfaces/badge/VehicleBadgeInterface";

export class VehicleBadge extends Badge {
    vehicle: VehicleInterface;

    constructor(data: VehicleBadgeInterface) {
        super(data);
        this.vehicle = data.vehicle;
    }
}
import "../common/plugins/router";
import Vue from "vue";
import VueI18n from "vue-i18n";
import './registerServiceWorker.ts'
import { messagesFr } from "./translations/messages.fr";

import { messagesEn } from "./translations/messages.en";
import vuetify from "../common/plugins/vuetify";
import "../common/plugins/vue_toast";

// @ts-ignore
import App from  "./App";
import store from "./store";
import router from "./router";
import "./scss/main.scss";

Vue.use(VueI18n);
const translations = {
  fr: messagesFr,
  en: messagesEn
};

export const i18n = new VueI18n({
  locale: "fr",
  messages: translations
});

new Vue({
  i18n,
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount("#app");

import Vue from 'vue'
import Vuex from 'vuex'
import Badge from './modules/badge';
import Session from './modules/session';
import Toast from './modules/toast';


Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    Badge,
    Session,
    Toast
  }
})

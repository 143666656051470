import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import Badge from "../..//models/Badge";
import PermanentBadgeApi from "../../../common/service/api/permanent_badge";
import TemporaryBadgeApi from "../../../common/service/api/temporary_badge";
import VisitorBadgeApi from "../../../common/service/api/visitor_badge";
import VehicleBadgeApi from "../../../common/service/api/vehicle_badge";
import PermanentBadgeFormInterface from "../../interfaces/badge/PermanentBadgeFormInterface";
import TemporaryBadgeFormInterface from "../../interfaces/badge/TemporaryBadgeFormInterface";
import VehicleBadgeFormInterface from "../../interfaces/badge/VehicleBadgeFormInterface";
import VisitorBadgeFormInterface from "../../interfaces/badge/VisitorBadgeFormInterface";
import { BadgeApi } from "../../../common/service/api/badge";
import BadgeMediaFormInterface from "../../interfaces/badge/BadgeMediaFormInterface";
import VisitReasonApi from "../../../common/service/api/visit_reason";
import { Enumeration } from "../../models/Enumeration";
import { EnumerationInterface } from "../../interfaces/EnumerationInterface";
import MediaInterface from "../../interfaces/MediaInterface";
import { ENTRYPOINT } from "../../../common/config/entrypoint";
import BadgeInterface from "../../interfaces/BadgeInterface";

@Module({ namespaced: true })
class BadgeModule extends VuexModule {
    public badge: BadgeInterface | null = null;
    public badges: Array<Badge> = [];
    public badgeApi: BadgeApi = new BadgeApi();
    public permanentBadgeApi: PermanentBadgeApi = new PermanentBadgeApi();
    public selectedCount: number = 0;
    public totalItemsCount: number = 0;
    public temporaryBadgeApi: TemporaryBadgeApi = new TemporaryBadgeApi();
    public vehicleBadgeApi: VehicleBadgeApi = new VehicleBadgeApi();
    public visitReasonApi: VisitReasonApi = new VisitReasonApi();
    public visitReasons: Array<Enumeration> = [];
    public visitorBadgeApi: VisitorBadgeApi = new VisitorBadgeApi();

    @Mutation
    setBadge(badge: BadgeInterface | null) {
        this.badge = badge;
    }

    @Mutation
    setSelectedCount(count: number) {
        this.selectedCount = count;
    }

    @Mutation
    setTotalItemsCount(count: number) {
        this.totalItemsCount = count;
    }

    @Mutation
    setVisitReasons(visitReasons: Array<Enumeration>) {
        this.visitReasons = visitReasons;
    }

    @Action
    async fetchVisitReasons(): Promise<void> {
        const response = await this.visitReasonApi.findAll({ params: { pagination: false } });
        const result = await response.json();
        const visitReasons = result["hydra:member"].map((visitReason: EnumerationInterface) => new Enumeration(visitReason));
        this.context.commit('setVisitReasons', visitReasons);
    }

    @Action
    async postDisable(payload: Object): Promise<void> {
        try {
            const response = await this.badgeApi.disable(payload);
            await response.json();

            this.context.commit('Toast/addSuccessToast', 'toast.success', { root: true })
        } catch (error) {
            this.context.commit('Toast/addErrorToast', 'toast.error', { root: true })
        }
    }

    @Action
    async postExport(payload: Object): Promise<void> {
        try {
            const response = await this.badgeApi.export(payload);
            await response.json();

            this.context.commit('Toast/addSuccessToast', 'toast.success', { root: true })
        } catch (error) {
            this.context.commit('Toast/addErrorToast', 'toast.error', { root: true })
        }
    }

    @Action
    async postMedia(form: BadgeMediaFormInterface): Promise<MediaInterface | null> {
        try {
            if (form.avatar === null && form.identity === null) {
                return null;
            }

            const formData = new FormData();

            if (form.avatar) {
                formData.append('avatar', form.avatar);
            }

            if (form.identity) {
                formData.append('identity', form.identity);
            }

            const response = await this.badgeApi.importFiles(form.badge, formData);
            const media = await response.json();

            if (!media) {
                throw new Error();
            }

            return media;
        } catch (error) {
            this.context.commit('Toast/addErrorToast', 'toast.error', { root: true })
            return null;
        }
    }

    @Action
    async postPermanent(form: PermanentBadgeFormInterface): Promise<void> {
        try {
            const response = await this.permanentBadgeApi.create(form);
            const badge = await response.json();
            await this.context.dispatch('postMedia', {
                avatar: form.avatar,
                identity: form.identityDocument,
                badge: badge.uuid
            });

            this.context.commit('Toast/addSuccessToast', 'toast.success', { root: true })
        } catch (error) {
            this.context.commit('Toast/addErrorToast', 'toast.error', { root: true })
        }
    }

    @Action
    async postPrint(payload: Object): Promise<void> {
        try {
            const response = await this.badgeApi.print(payload);
            const fileName = await response.text();

            if (fileName) {
                window.location.href = ENTRYPOINT + '/badges/actions/print/' + fileName;
            }

            this.context.commit('Toast/addSuccessToast', 'toast.success', { root: true })
        } catch (error) {
            this.context.commit('Toast/addErrorToast', 'toast.error', { root: true })
        }
    }

    @Action
    async postRenew(payload: Object): Promise<void> {
        try {
            const response = await this.badgeApi.renew(payload);
            await response.json();

            this.context.commit('Toast/addSuccessToast', 'toast.success', { root: true })
        } catch (error) {
            this.context.commit('Toast/addErrorToast', 'toast.error', { root: true })
        }
    }

    @Action
    async postSend(payload: any): Promise<void> {
        try {
            const response = await this.badgeApi.send(payload.uuid, { email: payload.email });
            await response.json();

            this.context.commit('Toast/addSuccessToast', 'toast.success', { root: true })
        } catch (error) {
            this.context.commit('Toast/addErrorToast', 'toast.error', { root: true })
        }
    }

    @Action
    async postTemporary(form: TemporaryBadgeFormInterface): Promise<void> {
        try {
            const response = await this.temporaryBadgeApi.create(form);
            const badge = await response.json();
            await this.context.dispatch('postMedia', {
                avatar: form.avatar,
                identity: form.identityDocument,
                badge: badge.uuid
            });

            this.context.commit('Toast/addSuccessToast', 'toast.success', { root: true })
        } catch (error) {
            this.context.commit('Toast/addErrorToast', 'toast.error', { root: true })
        }
    }

    @Action
    async postVehicle(form: VehicleBadgeFormInterface): Promise<void> {
        try {
            const response = await this.vehicleBadgeApi.create(form);
            await response.json();

            this.context.commit('Toast/addSuccessToast', 'toast.success', { root: true })
        } catch (error) {
            this.context.commit('Toast/addErrorToast', 'toast.error', { root: true })
        }
    }

    @Action
    async postVisitor(form: VisitorBadgeFormInterface): Promise<void> {
        try {
            const response = await this.visitorBadgeApi.create(form);
            const badge = await response.json();
            await this.context.dispatch('postMedia', {
                avatar: form.avatar,
                identity: form.identityDocument,
                badge: badge.uuid
            });

            this.context.commit('Toast/addSuccessToast', 'toast.success', { root: true })
        } catch (error) {
            this.context.commit('Toast/addErrorToast', 'toast.error', { root: true })
        }
    }

    @Action
    async updatePermanent(form: PermanentBadgeFormInterface): Promise<void> {
        try {
            const response = await this.permanentBadgeApi.update(form);
            const badge = await response.json();
            await this.context.dispatch('postMedia', {
                avatar: form.avatar,
                identity: form.identityDocument,
                badge: badge.uuid
            });

            this.context.commit('Toast/addSuccessToast', 'toast.success', { root: true })
        } catch (error) {
            this.context.commit('Toast/addErrorToast', 'toast.error', { root: true })
        }
    }

    @Action
    async updateState(payload: any) {
        try {
            const response = await this.badgeApi.updateState(payload.uuid, { state: payload.state })
            await response.json();

            this.context.commit('Toast/addSuccessToast', 'toast.success', { root: true })
        } catch (error) {
            this.context.commit('Toast/addErrorToast', 'toast.error', { root: true })
        }
    }

    @Action
    async updateTemporary(form: TemporaryBadgeFormInterface): Promise<void> {
        try {
            const response = await this.temporaryBadgeApi.update(form);
            const badge = await response.json();
            await this.context.dispatch('postMedia', {
                avatar: form.avatar,
                identity: form.identityDocument,
                badge: badge.uuid
            });

            this.context.commit('Toast/addSuccessToast', 'toast.success', { root: true })
        } catch (error) {
            this.context.commit('Toast/addErrorToast', 'toast.error', { root: true })
        }
    }

    @Action
    async updateVehicle(form: VehicleBadgeFormInterface): Promise<void> {
        try {

            const response = await this.vehicleBadgeApi.update(form);
            const badge = await response.json();

            this.context.commit('Toast/addSuccessToast', 'toast.success', { root: true })
        } catch (error) {
            this.context.commit('Toast/addErrorToast', 'toast.error', { root: true })
        }
    }

    @Action
    async updateVisitor(form: VisitorBadgeFormInterface): Promise<void> {
        try {
            const response = await this.visitorBadgeApi.update(form);
            const badge = await response.json();
            await this.context.dispatch('postMedia', {
                avatar: form.avatar,
                identity: form.identityDocument,
                badge: badge.uuid
            });

            this.context.commit('Toast/addSuccessToast', 'toast.success', { root: true })
        } catch (error) {
            this.context.commit('Toast/addErrorToast', 'toast.error', { root: true })
        }
    }
}

export default BadgeModule;

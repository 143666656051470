import { Component, Vue } from "vue-property-decorator";
import OptionInterface from "../interfaces/dataTable/OptionInterface";
import { Option } from "../models/dataTable/Option";
import { i18n } from '../main';

@Component({
    components: {}
})
export default class ListMixin extends Vue {
    options: OptionInterface = new Option();
    filters: any = {};
    footerProps: Object = {
        "items-per-page-options": [5, 10, 15, 20],
        "items-per-page-text": this.$t("vuetify.dataFooter.itemsPerPageText")
    }
    items: any = [];
    itemsPerPage = [5, 10, 15, 20];
    loading: boolean = false;

    get isLoading () {
        return this.loading;
    }

    // call getPage(filters: any)
    update(filters: any) {}

    // this is where you fetch api
    getPage(filters: any) {}

    // Prepare filters and call update(filters: any)
    async onUpdateOptions(props: Option): Promise<void> {
        this.loading = true;
        const { page, itemsPerPage, sortBy, sortDesc, descending, totalItems } = props;

        if (itemsPerPage > 0) {
            this.filters = { ...this.filters, itemsPerPage, page }
        }

        if (sortBy.length) {
            this.filters.order = sortBy.map((key: string, index: number) => `order[${key}]=${sortDesc[index] ? 'ASC' : 'DESC'}`).join('&')
        }

        await this.update(this.filters);
        this.loading = false;
    }
}


































































































import { Component, Vue } from "vue-property-decorator";
import LocalDialog from "../globals/LocalDialog.vue";
import ReportBadgeModal from "./ReportBadgeModal.vue";
import ReportFormInterface from "../../interfaces/report/ReportFormInterface";
import BadgeEditModal from "./BadgeEditModal.vue";
import { namespace } from "vuex-class";
import { ActionableMixin } from "../../mixins/actionable";
import BadgeInterface from "../../interfaces/BadgeInterface";

const badge = namespace("Badge");
@Component({
  components: { BadgeEditModal, ReportBadgeModal, LocalDialog }
})
export default class ShowActions extends ActionableMixin {
  @badge.State
  badge!: BadgeInterface;

  onClick(action: string) {
    this.$emit('click', action);
  }

  onReportCreate(form: ReportFormInterface) {
    this.$emit('reportCreate', form);
  }

  onReportUpdate(form: ReportFormInterface) {
    this.$emit('reportUpdate', form);
  }
}

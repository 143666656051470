import { EnumerationInterface } from "../interfaces/EnumerationInterface";
import AbstractModel from "../models/AbstractModel";

export class Enumeration extends AbstractModel implements EnumerationInterface {
    code: string;
    color?: string;
    enValue: string;
    frValue: string;
    position: string;

    constructor(data: EnumerationInterface) {
        super(data);
        this.code = data.code;
        this.enValue = data.enValue;
        this.frValue = data.frValue;
        this.position = data.position;
        this.color = data.color;
    }
}





















































import { Component, Prop, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";

const session = namespace("Session")
@Component({
  components: { }
})
export default class LocalDialog extends Vue {
  @Prop()
  width!: number;

  private dialog: boolean = false;

  public defaultWidth: number = 500;

  get getWidth() {
    return this.defaultWidth;
  }

  accept() {
    this.$emit('accept');
    this.dialog = false;
  }

  beforeMount() {
    this.defaultWidth = this.width;
  }

  cancel() {
    this.$emit('cancel');
    this.dialog = false;
  }
}



















































































import { FormMixin } from '../../mixins/form';
import VehicleBadgeFormInterface from "../../interfaces/badge/VehicleBadgeFormInterface";
import { Component } from "vue-property-decorator";
import { VForm } from "../../models/form/VForm";
import VehicleTypeApi from "../../../common/service/api/vehicle_type";
import { PaginatedHydraResponseInterface } from "../../interfaces/response/PaginatedHydraResponseInterface";
import { Enumeration } from "../../models/Enumeration";
import { EnumerationInterface } from "../../interfaces/EnumerationInterface";
import { namespace } from "vuex-class";
import VehicleInterface from "../../interfaces/vehicle/VehicleInterface";
import VehicleBadgeInterface from "../../interfaces/badge/VehicleBadgeInterface";

const badge = namespace("Badge");
const session = namespace("Session");
@Component({
  components: {}
})
export default class VehicleBadgeForm extends FormMixin {
  private formDefault: VehicleBadgeFormInterface =  {
    organization: `/api/organizations/${this.$route.params.id}`,
    registration: null,
    color: null,
    model: null,
    type: null
  };
  private form: VehicleBadgeFormInterface = { ...this.formDefault };
  private valid: boolean = false;
  private vehicleTypes: Array<Enumeration> = [];
  private vehicleTypeApi: VehicleTypeApi = new VehicleTypeApi();

  @badge.State
  badge!: VehicleBadgeInterface;

  @session.State
  language!: string;

  beforeMount() {
    this.vehicleTypeApi.findAll({ params: { pagination: false } })
    .then((response: any) => response.json())
    .then((visitReasons: PaginatedHydraResponseInterface) => {
      this.vehicleTypes = visitReasons["hydra:member"].map((vehicleType: EnumerationInterface) => new Enumeration(vehicleType));
    });

    if (this.badge) {
      this.form.color = this.badge.vehicle.color;
      this.form.model = this.badge.vehicle.model;
      if (this.badge.vehicle.type) {
        this.form.type = this.badge.vehicle.type["@id"];
      }
      this.form.registration = this.badge.vehicle.registration;
    }
  }

  submit() {
    this.valid = (this.$refs.vehicleForm as VForm).validate();

    if (this.valid) {
      this.$emit(this.badge ? 'update' : 'submit', this.form);
      this.form = { ...this.formDefault };
    }
  }
}

















import { Component, Vue } from "vue-property-decorator";
import { Organization } from "../../models/Organization";
import { namespace } from "vuex-class";
import Person from "../../models/Person";
import { ROLE_ADMIN, ROLE_SECURITY } from "../../../common/config/roles";
import { LOGIN_URI } from "../../../common/config/entrypoint";
import BadgeInterface from "../../interfaces/BadgeInterface";
import PersonalBadgeInterface from "../../interfaces/badge/PersonalBadgeInterface";

const session = namespace("Session");
const badge = namespace("Badge");
@Component({
  components: {},
})
export default class BadgeBreadcrumb extends Vue {

  private types: any = {
    PermanentBadge: "permanent",
    TemporaryBadge: "temporary",
    VehicleBadge: "vehicle",
    VisitorBadge: "visitor"
  }

  @session.State
  selectedOrganization!: Organization;

  @badge.State
  badge!: BadgeInterface | PersonalBadgeInterface | null;

  @session.State
  public user!: Person;

  get isAdmin() {
    if (!this.user || !this.user.roles) {
      return false;
    }

    return this.user?.roles.includes(ROLE_ADMIN) || this.user?.roles.includes(ROLE_SECURITY);
  }

  get breadcrumbLinks() {
    const path = this.$route.path;
    return this.$route.matched.flatMap((route: any) => {
        if (route.name) {

          if (!this.isAdmin && this.badge && route.name === 'BadgeShow') {
            return [
              {
                disabled: false,
                text: this.badge?.organization.legalName,
                to: `/organization/${this.badge?.organization.uuid}`,
              }, {
                disabled: false,
                text: this.$t("route." + route.name + this.badge['@type']),
                to: `/organization/${this.badge?.organization.uuid}/badges/${this.types[this.badge['@type']]}`,
              },
              {
                disabled: route.path === path || route.regex.test(path),
                text: 'Badge ' + (this.badge.givenName ?? '') + ' ' + (this.badge.familyName ?? ''),
                to: route.path,
              }
            ];
          }

          if (this.isAdmin && this.badge && route.name === 'BadgeShow') {
            return [
              {
                disabled: false,
                text: this.$t("route.OrganizationList"),
                to: '/organization/list',
              },
              {
                disabled: false,
                text: this.$t("organization.organization") + " " + this.badge?.organization.legalName,
                to: `/organization/${this.badge?.organization.uuid}`,
              }, {
                disabled: false,
                text: this.$t("route." + route.name + this.badge['@type']),
                to: `/organization/${this.badge?.organization.uuid}/badges/${this.types[this.badge['@type']]}`,
              },
              {
                disabled: route.path === path || route.regex.test(path),
                text: 'Badge ' + (this.badge.givenName ?? '') + ' ' + (this.badge.familyName ?? ''),
                to: route.path,
              }
            ]
          }

          return [
            {
              disabled: route.path === path || route.regex.test(path),
              text: this.$t("route." + route.name),
              to: route.path,
            }
          ];
        }
      }
    )
  };
}

import { Component, Vue } from "vue-property-decorator";
import CountryInterface from "../interfaces/CountryInterface";

@Component({
    components: {},
})
export class FormMixin extends Vue {
    emailRules: Array<any> = [
        (value: string) => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || this.$t('rules.invalidEmail')
        }
    ];

    fileRules: Array<any> = [
        (value: any) => !value || value.size < 10000000 || this.$t('rules.fileTooLarge')
    ];

    phoneRules: Array<any> = [
        (value: string) => {
            const pattern = /\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/;
            return pattern.test(value) || this.$t('rules.invalidPhone');
        }
    ];

    requiredRules: Array<any> = [
        (value: any) => !!value || this.$t('rules.required')
    ];

    siretRules: Array<any> = [
        (value: string) => {
            const pattern = /^[0-9]{14}$/
            return value == null || value == '' || pattern.test(value) || this.$t('rules.invalidSiret')
        }
    ];

    tvaRules: Array<any> = [
        (value: string) => {
            const pattern = /^[A-Z]{2}[0-9]{11}$/
            return value == null || value == '' || pattern.test(value) || this.$t('rules.invalidTva')
        }
    ];

    passwordRules: Array<any> = [
        (value: string) => {
            if (value) {
                const minLength = value?.length >= 8;
                const containsUppercase = /[A-Z]/.test(value);
                const containsLowercase = /[a-z]/.test(value);
                const containsNumber = /[0-9]/.test(value);
                const containsSpecial = /[#?!@$%^&*-]/.test(value);
                return minLength && containsUppercase && containsLowercase && containsNumber && containsSpecial || this.$t('rules.invalidPassword');
            }
            return true;
        }
    ];

    postalCodeRules: Array<any> = [
        (value: string) => {
            const pattern = /^[0-9]*$/
            return pattern.test(value) || this.$t('rules.invalidPostalCode')
        }
    ]


    getSiretRules(selectedCountry: CountryInterface | null) {
        return selectedCountry && selectedCountry.european ? this.requiredRules.concat(this.siretRules) : this.siretRules;
    }

    getTvaRules(selectedCountry: CountryInterface | null) {
        return selectedCountry && selectedCountry.european ? this.requiredRules.concat(this.tvaRules) : this.tvaRules;
    }


    textLengthRules (length: number): Array<any> {
        return [
            (value: any) => (value?.length < length || value === null) || this.$t('rules.textTooLong')
        ]
    }
}
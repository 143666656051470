import LanguageInterface from "../interfaces/LanguageInterface";
import VueI18n from "vue-i18n";

export default class Language implements LanguageInterface {
    i18n: VueI18n;
    language: string;

    constructor(i18n: VueI18n, language: string) {
        this.i18n = i18n;
        this.language = language;
    }
}
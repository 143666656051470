import { Component, Vue, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import BadgeView from "../../models/badge/BadgeView";
import BadgeListMixin from "./badge_list";
import Person from "../../models/Person";
import { ROLE_ADMIN, ROLE_SECURITY } from "../../../common/config/roles";

const badge = namespace("Badge");
const session = namespace("Session");
@Component({
    components: {}
})
export class BadgeActionMixin extends BadgeListMixin {
    public selected: Array<BadgeView> = [];
    private selectAll: boolean = false;

    @badge.Action
    postDisable!: (selected: Object) => void;

    @badge.Action
    postExport!: (selected: Object) => void;

    @badge.Action
    postPrint!: (selected: Object) => void;

    @badge.Action
    postRenew!: (selected: Object) => void;

    @badge.Action
    updateState!: (payload: any) => Promise<void>;

    @badge.Mutation
    setSelectedCount!: (count: number) => void;

    @badge.Mutation
    setTotalItemsCount!: (count: number) => void;

    @session.State
    user!: Person;

    onClickAction(action: string, badgeView: BadgeView) {
        this.selected = [badgeView];
        this.onClickMassAction(action);
    }

    canDisable() {
        if (this.user.roles === undefined) {
            return false;
        }

        return this.user.roles.indexOf(ROLE_ADMIN) > -1 || this.user.roles.indexOf(ROLE_SECURITY) > -1
    }

    onClickMassAction(action: string) {
        if (this.selected.length === 0) {
            return;
        }

        const payload = { ...this.filters, badges: this.selected.map((badgeView: BadgeView) => badgeView.uuid) }

        if (action === 'disable') {
            this.postDisable(payload);
            this.onUpdateOptions(this.options);
            return
        }

        if (action === 'export') {
            this.postExport(payload);
            this.onUpdateOptions(this.options);
            return;
        }

        if (action === 'print') {
            this.postPrint(payload);
            this.onUpdateOptions(this.options);
            return;
        }

        if (action === 'renew') {
            this.postRenew(payload);
            this.onUpdateOptions(this.options);
            return;
        }
    }

    @Watch('selected')
    onSelected(value: Array<string>) {
        const count = this.filters.selectAll && this.options.totalItems !== null  ? this.options.totalItems : value.length;

        this.setSelectedCount(count);

        if (this.options.totalItems === null) {
            return;
        }

        this.setTotalItemsCount(this.options.totalItems);
    }

    onSelectAll() {
        this.selectAll = !this.selectAll;
        this.selected = this.selectAll ? this.items : [];
        this.filters.selectAll = this.selectAll;
    }
}
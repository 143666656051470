export const messagesEn = {
    "alert": {
        "close": "Close",
        "error": "An error occured.",
        "info": "Info",
        "success": "The operation is successfull."
    },
    "pwa": {
        "download": "Download app",
        "help1": "To install :",
        "help2": "Browser's options > Add to homescreen "
    },
    "actions": {
        "actions": "Actions",
        "addFile": "Import file",
        "cancel": "Cancel",
        "submit": "Submit",
        "edit": "Edit",
        "create": "Create"
    },
    "admin": {
        "badgeOrganization": "Enterprise",
        "gpmd": "GPMD/LANCRY users",
        "person": "Users",
        "price": "Badge price",
        "trustedOrganization": "Trusted organization",
        "shipperOrganization": "External organization / Shipper",
        "seamanOrganization": "External organization / Seaman",
        "providerOrganization": "External organization / Provider"
    },
    "badge": {
        "actions": {
            "update": "Update badge",
            "print": "Print badge",
            "renew": "Renew badge",
            "report": "Report an issue",
            "disable": "Disable badge",
            "delete": "Delete badge",
            "preorder": "Preorder",
            "topay": "To pay"
        },
        "backToList": "Back to the badge's list",
        "massActions": {
            "renewal": "Renew selection",
            "disable": "Disable selection",
            "print": "Print selection",
            "export": "Export selection",
        },
        "addVehicle": "Add new vehicle",
        "card": {
            "partner": "Partners",
            "send": "Send badge",
            "validity": "Validity",
            "from": "From",
            "to": "To"
        },
        "chooseType": "Choose a badge type",
        "create": "New badge",
        "confirmActionDisable": "You are going to disable ",
        "confirmActionPrint": "You are going to print ",
        "confirmActionRenew": "You are going to renew ",
        "confirmActionContinue": "badges, do you want to continue ?",
        "confirmActionState": "Badge's state is going to change, do you want to continue ?",
        "effectiveDate": "Badge's validity date",
        "endDate": "End date",
        "email": "Email",
        "expiresAt": "Expiration date",
        "familyName": "Family name",
        "firstPermanentBadge": "Permanent badge (first)",
        "fullName": "Identity",
        "givenName": "Given name",
        "identity": "Join identity card",
        "identityTitle": "Identity card",
        "jobTitle": "Job title",
        "new": "New badge",
        "organizationLegalName": "organization's legal name",
        "orderNumber": "Order number",
        "permanent": "Permanent badge",
        "publicService": "Public service",
        "registration": "Registration",
        "selectedCount1": "You have selected",
        "selectedCount2": "lines over",
        "selectedCount3": "click here to de/select all others lines of the table",
        "sentTitle1": "Badge sent by mail to",
        "sentTitle2": ", the ",
        "state": "State",
        "startDate": "Start date",
        "temporary": "Temporary badge",
        "title": {
            "global": "Badges list",
            "permanent": "Permanent badges list",
            "temporary": "Temporary badges list",
            "vehicle": "Vehicle badges list",
            "visitor": "Visitor badges list"
        },
        "type": "Type",
        "vehicle": "Vehicle badge",
        "visitor": "Visitor badge",
        "vehicleColor": "Vehicle's color",
        "vehicleModel": "Vehicle's model",
        "vehicleTitle": "Vehicles",
        "vehicleType": "Vehicle's type",
        "visitReason": "Visit reason"
    },
    "badgeOrganization": {
        "add": "Add new element",
        "code": "Code",
        "name": "Nom"
    },
    "cart": {
        "adminPay": "As a courtesy",
        "badgeNumber": "Badge number",
        "basket": "in cart",
        "pay": "Pay order",
        "permanentCount": "Permanent individual badge - ",
        "permanentLink": "permanent badges",
        "permanentUnitPrice": "Permanent individual badge - ",
        "totalPrice": "Total price",
        "payText": "La vente de badges est exonérée de TVA en application de l’article 262-II-7° du Code général des impôts",
        "status": "Your organization needs to be accepted first by administrator",
        "unitPrice": "Unit price",
        "vehicleCount": "Individual vehicle badge - ",
        "vehicleLink": "vehicle badges ",
        "vehicleUnitPrice": "Individual vehicle badge- "
    },
    "confirmation": "Confirmation",
    "exception": {
        "badgeLimit": "You have reached the limit of 6 badges",
    },
    "guardHouse": {
        "title": "What job do you hold ?",
        "label": "Your job"
    },
    "header": {
        "admin": "Admin",
        "badges": "Badges",
        "basket": "Cart in progress",
        "commands": "Commands",
        "history": "Order history",
        "notice": "Notice",
        "organization": "Organization",
        "organizations": "Organizations",
        "organizationUserList": "Users",
        "partnerships": "Partnerships",
        "permanent_badges": "Permanent badges",
        "stats": "Stats",
        "temporary_badges": "Temporary badges",
        "vehicle_badges": "Vehicle badges",
        "visitors": "Visitors",
        "user_edit": "Modification of personal information",
        "user_account": "Personal information"
    },
    "menu": {
        "myAccount": "My Account",
        "logout": "Logout"
    },
    "organization": {
        "accepted": "Accepted",
        "rejected": "Refused",
        "comment": "Commentaire de refus",
        "identificationNumber": "Code tiers",
        "state": "Validation administrative",
        "list": "organization list",
        "organization": "Company",
        "manager": "Référent société",
        "identity_title": "organization's identity",
        "postalCode_title": "Correspondance address",
        "manager_title": "Manager",
        "partnership_title": "Partnership request",
        "legalName": "Legal Name",
        "acronym": "Acronym",
        "country": "Country",
        "siret": "N° de SIRET",
        "tva": "TVA number",
        "type": "Typology",
        "telephone": "Phone",
        "email": "Email",
        "fax": "Fax",
        "badgeCount": "Badge number",
        "requiredAction": "Action requise",
        "search": "Search a organization",
        "success_message": "Your information have been save. An email will be sent to your manager.",
        "success_title": "Tahnk you !",
        "file": {
            "1": "(1) For France, join an 6 month old K.BIS extract of the organization for your first deposit.",
            "2": "(2) For the seaman join a copy of your seaman card."
        }
    },
    "password": {
        "new": "New password",
        "repeat": "Repeat password",
    },
    "partnership": {
        "createdAt": "Deposit date",
        "duration": "Duration",
        "askedBy": "Partnership ask by",
        "partnerManager": "External organization's manager",
        "trustedPartner": "Trusted organization",
        "trustedPartnerManager": "Trusted organization's manager",
        "state": "State",
        "updatedAt": "State updated at",
        "badgeCount": "Badge count",
        "validate": "Accept partnerhsip",
        "refuse": "Refuse partnership"
    },
    "postalCode": {
        "complement": "Secondary",
        "country": "Country",
        "postalCode": "Postal code",
        "route": "Route",
        "locality": "Locality",
    },
    "person": {
        "add": "New user",
        "familyName": "Family Name",
        "givenName": "Given Name",
        "jobTitle": "Job Title",
        "telephone": "Phone",
        "mobilePhone": "Mobile Phone",
        "lang": "Lang",
        "email": "Mail",
        "role": "Role"
    },
    "price": {
        "create": "Créer une nouvelle grille de prix",
    },
    "report": {
        "add": "Report",
        "comment": "Comment",
        "delete": "Delete",
        "empty": "No report",
        "title1": "Report of",
        "title2": "at",
        "title3": "from",
        "update": "Update"
    },
    "rules": {
        "usedEmail": "Email already used",
        "invalidEmail": "Invalid email",
        "invalidPhone": "Invalid format expected exemple: +33",
        "invalidSiret": "Wrong format",
        "invalidTva": "Wrong format",
        "invalidPostalCode": "Invalid format",
        "fileTooLarge": "File should be less than 10Mo",
        "required": "This field is required",
        "textTooLong": "Text is too long",
        "usedSiret": "SIRET already used",
        "usedTva": "TVA number already used",
        "invalidPassword": "The password must be at least 8 characters long, including one uppercase, one lowercase, one special character and one number",
        "identicalPassword": "Passwords must be the same",
    },
    "supplyOrder": {
        "badgeCount": "Badge number",
        "createdAt": "Date",
        "invoiceNumber": "SIGF number",
        "number": "Order number",
        "show": "Order detail",
        "state": "State",
        "total": "Total cost (€)"
    },
    "tooltip": {
        "badge": {
            "temporary": "Temporary badge has a maximal validity of 7 consecutive days and can't be renewed more than 5 timees in a year for the same organization (equivalent of 5 visit)"
        }
    },
    "ROLE_ADMIN": "Admin",
    "ROLE_BADGE_HOLDER": "Badge holder",
    "ROLE_EXTERNAL_COMPANY": "External organization",
    "ROLE_GATEMAN": "Gateman",
    "ROLE_VIDEOMAN": "Videoman",
    "ROLE_LIGHT_RESIDENT_COMPANY": "Trusted light organization",
    "ROLE_RESIDENT_COMPANY": "Trusted organization",
    "ROLE_SECURITY": "Security office",
    "route": {
        "Home": "Home",
        "BadgeList": "Badges",
        "Organization": "Organization",
        "OrganizationCreate": "Create a badge request",
        "OrganizationEdit": "Modify Organization",
        "OrganizationList": "Organization List",
        "OrganizationShow": "Organization's details",
        "OrganizationUserList": "Users",
        "OrganizationBadgeList": "Badges",
        "OrganizationBadgeListPermanent" : "Badges permanents",
        "OrganizationBadgeListVehicle" : "Badges véhicules",
        "BadgeShowPermanentBadge" : "Badges permanents",
        "BadgeShowVehicleBadge": "Badges véhicules",
        "BadgeShowTemporaryBadge": "Badges temporaires",
    },
    "vue_phone_number": {
        "countrySelectorLabel": 'Country code',
        "countrySelectorError": 'Select country',
        "telephoneNumberLabel": 'Phone number',
        "mobilePhoneNumberLabel": 'Mobile phone number',
        "example": 'Example :'
    },
    "vuetify": {
        "dataFooter": {
            "itemsPerPageText": "Items per page"
        }
    },
    "accepted": "Accepted",
    "new": "New",
    "login": {
        "text": "In order to gain permanent (1 year) access to the ZNLAP, all accessors : \n" +
            "State services, residents (e.g., port facilities and resident companies, located within the ZNLAP), and\n" +
            "Port services (e.g., driving, tugboat, boatage)\n" +
            "GPMD rail operators), partners (e.g. all other companies with a professional link to the GPMD)" + "resident companies or the GPMD\n" +
            "resident companies or the GPMD" + "service providers\n" +
            "(e.g. service providers, suppliers, transporters, subcontractors), must fill out an application form available on the website.\n" +
            " pass application available in this application.",
        "badge": "Apply for a badge for the first time",
        "welcome": "Welcome",
        "email": {
            "label": "Email address",
            "placeholder": "Email"
        },
        "password": {
            "label": "Password",
            "placeholder": "Password"
        },
        "newPassword": {
            "label": {
                "first": "New password",
                "second": "Please confirm your new password",
            },
            "placeholder": {
                "first": "Password",
                "second": "Password",
            }
        },
        "actions": {
            "login": "Sign in",
            "request": "Forgot your password ?",
            "lang": "Switch language",
            "requestPassword": "Ask a new password",
            "reset": "Change my password"
        },
    },
    "refused": "Refused",
    "PermanentBadge": "permanent",
    "VehicleBadge": "vehicle",
    "VisitorBadge": "visitor",
    "TemporaryBadge": "temporary"
};










































































import { Component, Vue } from "vue-property-decorator";
import { Cropper } from 'vue-advanced-cropper'
import { FormMixin } from "../../mixins/form";

@Component({
  components: { Cropper }
})
export default class Avatar extends FormMixin {
  private computedImage: any = null;
  private dialog: boolean = false;
  private imgSrc: any = null;
  private preview: any = null;

  loadImage(file: File) {
    if (file.size >= 10000000) {
      return;
    }
    const reader = new FileReader();

    reader.onload = (e) => {
      if (e.target && e.target.result) {
        this.imgSrc = e.target.result;
      }
    };

    reader.readAsDataURL(file);
  }

  onChange(data: any) {
    data.canvas.toBlob((blob: Blob) => {
      this.computedImage = blob;
    });
    this.preview = data.canvas.toDataURL();
  }

  onClose() {
    this.$emit('update', this.computedImage);
    this.dialog =  false;
  }
}

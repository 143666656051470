






import {Component, Vue} from 'vue-property-decorator';

@Component({
    components: { }
})
export default class Loading extends Vue {
  // @Prop({required: true, default: true}) visible: boolean
};

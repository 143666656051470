




























































import { Component, Prop, Vue } from 'vue-property-decorator';
import Report from "../../models/Report";
import ReportBadgeModal from "./ReportBadgeModal.vue";
import { namespace } from "vuex-class";
import ReportFormInterface from "../../interfaces/report/ReportFormInterface";
import ReportFilterInterface from "../../interfaces/report/ReportFilterInterface";
import PersonalBadgeInterface from "../../interfaces/badge/PersonalBadgeInterface";
import PermanentBadgeInterface from "../../interfaces/badge/PermanentBadgeInterface";
import TemporaryBadgeInterface from "../../interfaces/badge/TemporaryBadgeInterface";

const badge = namespace("Badge");
const report = namespace("ReportModule")
@Component({
  components: { ReportBadgeModal }
})
export default class BadgeReportCard extends Vue {

  private loading: boolean = false;

  @badge.State
  badge!: PersonalBadgeInterface | PermanentBadgeInterface | TemporaryBadgeInterface;

  @report.State
  reports!: Array<Report>;

  @report.Action
  deleteReport!: (report: Report) => Promise<void>

  @report.Action
  getReports!: (filters: ReportFilterInterface) => Promise<void>;

  @report.Action
  updateReport!: (form: ReportFormInterface) => Promise<Report | null>

  mounted() {
    this.getReports({ 'badge.uuid': this.badge.uuid });
  }

  async onReportUpdate(form: ReportFormInterface) {
    this.loading = true;
    await this.updateReport(form);
    await this.getReports({ 'badge.uuid': this.badge.uuid });
    this.loading = false;
  }

  async onDelete(report: Report) {
    this.loading = true;
    await this.deleteReport(report);
    await this.getReports({ 'badge.uuid': this.badge.uuid });
    this.loading = false;
  }
}

import IdentifierInterface from "../interfaces/IdentiferInterface";
import BadgeInterface from "../interfaces/BadgeInterface";
import { OrganizationInterface } from "../interfaces/OrganizationInterface";
import { EnumerationInterface } from "../interfaces/EnumerationInterface";

export default class Badge implements BadgeInterface {
    "@id": string;
    "@type": string;
    actions?: Array<string>;
    uuid: string;
    effectiveDate: string | null;
    expiresAt: string | null;
    givenName: string;
    familyName: string;
    language: string;
    number: string;
    organization: OrganizationInterface;
    publicService: boolean;
    qrCode: string;
    barCode: string;
    state: EnumerationInterface;
    tokenUrl?: string;

    constructor(data: BadgeInterface) {
        this["@id"] = data["@id"];
        this.actions = data.actions;
        this.uuid = data.uuid;
        this.effectiveDate =  data.effectiveDate !== null ? new Date(data.effectiveDate).toISOString().substr(0, 10) : data.effectiveDate;
        this.expiresAt = data.expiresAt !== null ? new Date(data.expiresAt).toISOString().substr(0, 10) : data.expiresAt;
        this.language = data.language;
        this.givenName = data.givenName;
        this.familyName = data.familyName;
        this.number = data.number;
        this.qrCode = data.qrCode;
        this.barCode = data.barCode;
        this.organization = data.organization;
        this.publicService = data.publicService;
        this.state = data.state;
        this["@type"] = data["@type"];

        if (data.tokenUrl) {
            this.tokenUrl = data.tokenUrl;
        }
    }
}










import { Component, Vue } from "vue-property-decorator";
import UserService from "../../services/user_service";

@Component({
    components: { }
})
export default class Layout extends Vue {
  private userService: UserService = new UserService();

  async mounted() {
    const token = await this.userService.getToken();

    if (token) {
      this.$router.push({ name: 'BadgeShow', params: { token }});
    }
  }
}

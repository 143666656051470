















import { Component, Vue, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import LanguageModel from "../../models/Language";
import LanguageInterface from "../../interfaces/LanguageInterface";

const session = namespace("Session");
@Component({
    components: { },
})
export default class Language extends Vue {
  @session.State
  public language!: string;

  @session.Mutation
  public setLanguage!: (payload: LanguageInterface) => void;

  changeLanguage(language: string) {
    this.setLanguage(new LanguageModel(this.$i18n, language));
  }

}

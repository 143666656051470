







import { Component, Vue } from "vue-property-decorator";
import ToastContainer from "../globals/ToastContainer.vue";
import BadgeBreadcrumb from "./BadgeBreadcrumb.vue";

@Component({
  components: {BadgeBreadcrumb, ToastContainer }
})
export default class BadgeLayout extends Vue {
}

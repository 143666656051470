import PersonInterface from "../interfaces/PersonInterface";
import AbstractModel from "./AbstractModel";
import { Organization } from "./Organization";

export default class Person extends AbstractModel implements PersonInterface {
    actions?: Array<string>
    email?: string;
    familyName?: string;
    fullName?: string;
    givenName?: string;
    jobTitle?: string;
    language?: string;
    mobilePhone?: string;
    organization?: Organization;
    readonly roles?: Array<string>;
    telephone?: string;

    constructor(
        data: PersonInterface
    ) {
        super(data);
        // @ts-ignore
        Object.keys(data).map((key: string) => Object.assign(this, { [key]: data[key] }));
    }
}

import BadgeViewInterface from "../../interfaces/badge/BadgeViewInterface";
import AbstractModel from "../AbstractModel";
import VehicleBadgeInterface from "../../interfaces/badge/VehicleBadgeInterface";

export default class BadgeView extends AbstractModel implements BadgeViewInterface {
    avatar: string | null;
    counter: string | null;
    effectiveDate: Date | null;
    email: string | null;
    enStateValue: string;
    enVehicleType: string | null;
    enVisitReason: string | null;
    enterpriseName: string;
    expiresAt: Date | null;
    frStateValue: string;
    frVehicleType: string | null;
    frVisitReason: string | null;
    fullName: string | null;
    jobTitle: string | null;
    number: string | null;
    organizationCurrentPartnership: string;
    organizationLegalName: string;
    organizationTypeCode: string;
    organizationUuid: string;
    publicService: boolean | null;
    registration: string | null;
    state: string;
    qrCode: string;
    stateColor: string;
    supplyOrderNumber: string;
    supplyOrderUuid: string;
    token: string;
    type: string;
    vehicleColor: string | null;
    vehicleModel: string | null;
    vehicles: Array<VehicleBadgeInterface>;

    constructor(data: BadgeViewInterface) {
        super(data);
        this.avatar = data.avatar;
        this.counter = data.counter;
        this.effectiveDate = data.effectiveDate !== null ? new Date(data.effectiveDate) : null;
        this.email = data.email;
        this.enStateValue = data.enStateValue;
        this.enVehicleType = data.enVehicleType;
        this.enVisitReason = data.enVisitReason;
        this.enterpriseName = data.enterpriseName;
        this.expiresAt = data.expiresAt !== null ? new Date(data.expiresAt) : null;
        this.frStateValue = data.frStateValue;
        this.frVehicleType = data.frVehicleType;
        this.frVisitReason = data.frVisitReason;
        this.fullName = data.fullName;
        this.jobTitle = data.jobTitle;
        this.number = data.number;
        this.organizationCurrentPartnership = data.organizationCurrentPartnership;
        this.organizationLegalName = data.organizationLegalName;
        this.organizationTypeCode = data.organizationTypeCode;
        this.organizationUuid = data.organizationUuid;
        this.publicService = data.publicService;
        this.qrCode = data.qrCode;
        this.registration = data.registration;
        this.state = data.state;
        this.stateColor = data.stateColor;
        this.supplyOrderNumber = data.supplyOrderNumber;
        this.supplyOrderUuid = data.supplyOrderUuid;
        this.token = data.token;
        this.type = data.type;
        this.vehicleColor = data.vehicleColor;
        this.vehicleModel = data.vehicleModel;
        this.vehicles = data.vehicles;
    }


}
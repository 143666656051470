









































































































































































import { FormMixin } from '../../mixins/form';
import { Component } from "vue-property-decorator";
import VisitorBadgeFormInterface from "../../interfaces/badge/VisitorBadgeFormInterface";
import { VForm } from "../../models/form/VForm";
import { Enumeration } from "../../models/Enumeration";
import { namespace } from "vuex-class";
import Avatar from "../globals/Avatar.vue";
import { mixins } from "vue-class-component";
import { BadgeOrganizationMixin } from "../../mixins/badge_organization/badge_organization";
import Phone from "../../components/globals/Phone.vue";
import TemporaryBadgeInterface from "../../interfaces/badge/TemporaryBadgeInterface";

const badge = namespace("Badge");
const session = namespace("Session");
@Component({
  components: { Avatar, Phone }
})
export default class VisitorBadgeForm extends mixins(BadgeOrganizationMixin, FormMixin) {
  private formDefault: VisitorBadgeFormInterface =  {
    avatar: null,
    email: null,
    familyName: null,
    givenName: null,
    identityDocument: null,
    language: 'FR',
    organization: `/api/organizations/${this.$route.params.id}`,
    enterprise: null,
    jobTitle: null,
    effectiveDate: null,
    telephone: null,
    visitReason: null
  };
  private form: VisitorBadgeFormInterface = { ...this.formDefault };

  private menu: boolean = false;
  private valid: boolean = false;

  @badge.State
  badge!: TemporaryBadgeInterface;

  @badge.State
  visitReasons!: Array<Enumeration>;

  @session.State
  language!: string;

  @badge.Action
  fetchVisitReasons!: () => void;

  beforeMount() {
    if (this.badge) {
      this.form.email = this.badge.email;
      this.form.effectiveDate = this.badge.effectiveDate;
      this.form.familyName = this.badge.familyName;
      this.form.givenName = this.badge.givenName;
      this.form.enterprise = this.badge.enterprise["@id"];
      this.form.jobTitle = this.badge.jobTitle;
      this.form.language = this.badge.language;
      this.form.effectiveDate = this.badge.effectiveDate;
      this.form.telephone = this.badge.telephone;
      this.form.visitReason = this.badge.visitReason["@id"];
    }
  }

  mounted() {
    this.fetchVisitReasons();
    this.getBadgeOrganizations({ pagination: false });
  }

  onAvatarUpdate(file: Blob) {
    this.form.avatar = file;
  }

  submit() {
    this.valid = (this.$refs.visitorForm as VForm).validate();

    if (this.valid) {
      this.$emit(this.badge ? 'update' : 'submit', this.form);
      this.form = { ...this.formDefault };
    }
  }
}

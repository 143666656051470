import Api from "./api";
import fetch from '../../utils/fetch';
import OrganizationStateFormInterface from "../../../src/interfaces/organization/OrganizationStateFormInterface";


export class OrganizationApi extends Api {
    constructor() {
        super('organizations');
    }

    importFiles(uuid: string, formData: FormData) {
        return fetch(`${this.endpoint}/${uuid}/medias`, { method: 'POST', body: formData } )
    }

    postState(payload: OrganizationStateFormInterface) {
        return fetch(`${this.endpoint}/${payload.organization}/state`, {method: 'POST', body: JSON.stringify(payload)})
    }

    getOrderCart(item: any) {
        return fetch(`${this.endpoint}/${item.uuid}/cart`)
    }
}

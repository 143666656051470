




































































import { Component, Prop, Vue } from "vue-property-decorator";
import PersonalBadgeInterface from "../../interfaces/badge/PersonalBadgeInterface";
import PermanentBadgeInterface from "../../interfaces/badge/PermanentBadgeInterface";
import TemporaryBadgeInterface from "../../interfaces/badge/TemporaryBadgeInterface";
import { namespace } from "vuex-class";
import { ORGANIZATION_TYPE_RESIDENT } from "../../../common/config/organization_types";
import { MediaApi } from "../../../common/service/api/media";
import VueBarcode from "vue-barcode";
import SendBadgeModal from "./SendBadgeModal.vue";
import { ActionableMixin } from "../../mixins/actionable";

const badge = namespace("Badge");
const session = namespace("Session");
@Component({
  components: { SendBadgeModal, VueBarcode }
})
export default class BadgeCard extends ActionableMixin {
  private qrcode: boolean = true;
  private mediaApi: MediaApi = new MediaApi();
  private trustedOrganization: string = ORGANIZATION_TYPE_RESIDENT;

  @badge.State
  badge!: PersonalBadgeInterface | PermanentBadgeInterface | TemporaryBadgeInterface;

  @session.State
  language!: string;

  @badge.Action
  postSend!: (payload: any) => void;

  get avatar() {
    if (!this.badge || !this.badge.avatar) {
      return "";
    }

    return this.mediaApi.getDownloadUri(this.badge.avatar["@id"]);
  }

  sendBadge(email: string) {
    this.postSend({ uuid: this.badge.uuid, email });
  }
}

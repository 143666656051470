const host :string = `${window.location.protocol}//mobile-passport2.athome-solution.fr`;
const remote: string = `${window.location.protocol}//passport2.athome-solution.fr`;
export const HOST = host;
export const REMOTE = remote;
export const ENTRYPOINT = remote + (host.endsWith('/') ? 'api' : '/api');
export const ADMIN_URI = host + (host.endsWith('/') ? 'admin' : '/admin');
export const LOGOUT_URI = host + (host.endsWith('/') ? 'logout' : '/logout');
export const LOGIN_URI = host + (host.endsWith('/') ? 'login' : '/login');
export const ACCESS_DENIED_URI = host + (host.endsWith('/') ? 'unauthorized' : '/app/unauthorized');

export const CACHE_NAME = "auth";
export const TOKEN_KEY = "token";
export const TOKEN = "sRKWQu6hCJgR25lslcf5s12FFVau0ugi";
export const FAKE_ENDPOINT = "/token";


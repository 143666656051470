import { render, staticRenderFns } from "./BadgeBreadcrumb.vue?vue&type=template&id=7a96338e&scoped=true&"
import script from "./BadgeBreadcrumb.vue?vue&type=script&lang=ts&"
export * from "./BadgeBreadcrumb.vue?vue&type=script&lang=ts&"
import style0 from "./BadgeBreadcrumb.vue?vue&type=style&index=0&id=7a96338e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a96338e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
installComponents(component, {VBreadcrumbs})

import fetch from '../../utils/fetch';
import Api from "./api";

export default class UserApi extends Api {
    constructor() {
        super('people');
    }

    findCurrentUser () {
        return fetch(`${this.endpoint}/me`);
    }

    findEmail(params: any) {
        return fetch(`${this.endpoint}/exist`, params);
    }
}

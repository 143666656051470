import Api from "./api";
import fetch from "../../utils/fetch";
import BadgeInterface from "../../../src/interfaces/BadgeInterface";

export class BadgeApi extends Api {
    constructor() {
        super("badges");
    }

    importFiles(uuid: string, formData: FormData) {
        return fetch(`${this.endpoint}/${uuid}/medias`, { method: 'POST', body: formData } )
    }

    disable(payload: Object) {
        return fetch(`${this.endpoint}/actions/disable`, { method: 'POST', body: JSON.stringify(payload) } )
    }

    export(payload: Object) {
        return fetch(`${this.endpoint}/actions/export`, { method: 'POST', body: JSON.stringify(payload) } )
    }

    print(payload: Object) {
        return fetch(`${this.endpoint}/actions/print`, { method: 'POST', body: JSON.stringify(payload) } )
    }

    renew(payload: Object) {
        return fetch(`${this.endpoint}/actions/renew`, { method: 'POST', body: JSON.stringify(payload) } )
    }

    send(uuid: string, payload: Object) {
        return fetch(`${this.endpoint}/${uuid}/send`, { method: 'POST', body: JSON.stringify(payload) })
    }

    getLogSent(payload: BadgeInterface) {
        return fetch(`${this.endpoint}/${payload.uuid}/logs/sent`)
    }

    getByToken(token: string) {
        return fetch(`/api/external/${this.endpoint}/${token}`)
    }

    updateState(uuid: string, payload: Object) {
        return fetch(`${this.endpoint}/${uuid}/state`, { method: 'PUT', body: JSON.stringify(payload) })
    }
}

import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue';
import Vuetify from "vuetify";
import 'vuetify/dist/vuetify.min.css'


Vue.use(Vuetify);

const BLUE = '#012B4F';
const YELLOW = '#F2A200';
const LIGHT_GREY = '#FBFBFB';
const PERMANENT = '#46C4E8';
const TEMPORARY = '#295577';
const VEHICLE = '#FFCC63';
const VISITOR = '#2B7F85';

const theme = {
    icons: {
        iconfont: 'mdi'
    },
    dark: false,
    themes: {
        dark: {
            primary: BLUE,
            secondary: YELLOW,
            lightGrey: LIGHT_GREY
        },
        light: {
            permanent: PERMANENT,
            temporary: TEMPORARY,
            vehicle: VEHICLE,
            visitor: VISITOR,
            primary: BLUE,
            secondary: YELLOW,
            lightGrey: LIGHT_GREY
        }
    }
};

export default new Vuetify({ theme });

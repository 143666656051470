import Api from "./api";
import fetch from "../../utils/fetch";
import {HOST, REMOTE} from "../../config/entrypoint";


export class MediaApi extends Api {
    constructor() {
        super('medias');
    }

    getDownloadUri(id: any) {
        return `${REMOTE}${id}/download`;
    }
}
import { Module, Mutation, VuexModule } from 'vuex-module-decorators';
import ToastInterface from "../../interfaces/ToastInterface";
import Toast from "../../models/Toast";
import { errorOptions, infoOptions, successOptions } from "../../../common/plugins/vue_toast";

@Module({ namespaced: true })
export default class ToastModule extends VuexModule {
    public toasts: Array<ToastInterface> = [];

    @Mutation
    addErrorToast(message: string) {
        const toast = new Toast(errorOptions)
        toast.message = message;

        this.toasts.push(toast);
    }

    @Mutation
    addInfoToast(message: string) {
        const toast = new Toast(infoOptions)
        toast.message = message;

        this.toasts.push(toast);
    }

    @Mutation
    addSuccessToast(message: string) {
        const toast = new Toast(successOptions)
        toast.message = message;

        this.toasts.push(toast);
    }

    @Mutation
    clear() {
        this.toasts = [];
    }

    @Mutation
    removeToast(data: ToastInterface) {
        const index = this.toasts.indexOf(data);
        if (index) {
            this.toasts.splice(index, 1);
        }
    }
}

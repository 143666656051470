import {Action, Module, Mutation, VuexModule} from 'vuex-module-decorators';
import Person from "../../models/Person";
import UserService from "../../services/user_service";
import LanguageInterface from "../../interfaces/LanguageInterface";
import VueI18n from "vue-i18n";
import UserApi from "../../../common/service/api/user";
import LocalStorage from "../../../common/utils/local_storage";
import { Organization } from "../../models/Organization";
import { OrganizationApi } from "../../../common/service/api/organization";

@Module({namespaced: true})
class SessionModule extends VuexModule {
    public dialog: boolean = false;
    public language: string = "fr";
    public organizationApi: OrganizationApi = new OrganizationApi();
    public selectedOrganization: Organization | null = null;
    public userService: UserService = new UserService();
    public user: Person | null = null;

    private localStorage: LocalStorage = new LocalStorage();
    private userApi: UserApi = new UserApi();

    @Mutation
    setDialog(show: boolean) {
        this.dialog = show;
    }

    @Mutation
    setLanguage(payload: LanguageInterface) {
        this.userService.setCurrentLanguage(payload.language);
        payload.i18n.locale = payload.language;
        this.language = payload.language;
    }

    @Mutation
    setLocaleLanguage(language: string) {
        this.language = language;
    }

    @Mutation
    setSelectedOrganization(organization: Organization | null) {
        this.selectedOrganization = organization;
    }

    @Mutation
    setUser(person: Person) {
        this.user = person;
    }

    @Action
    async updateOrganization(): Promise<void> {
        if (this.user === null || this.user.organization === undefined) {
            return;
        }
        const response = await this.organizationApi.find(this.user.organization["@id"]);
        const organization = await response.json();
        this.context.commit('setOrganization', new Organization(organization));
    }

    @Action
    async updateSelectedOrganization(uuid: string): Promise<void> {
        if (this.user === null) {
            return;
        }

        const response = await this.organizationApi.find(`/api/organizations/${uuid}`);
        const organization = await response.json();

        this.context.commit('setSelectedOrganization', new Organization(organization));
    }

    @Action
    updateLanguage(i18n: VueI18n) {
        const localStorageLanguage = this.userService.getCurrentLanguage();
        this.context.commit('setLocaleLanguage', localStorageLanguage);
        i18n.locale = localStorageLanguage;
    }

    @Action
    updateUser(): void {
        this.userApi.findCurrentUser()
            .then((response: any) => response.json())
            .then((data: any) => {
                if (data === null) {
                    return;
                }
                const person = new Person(data);
                this.localStorage.saveItem("user", person);
                this.context.commit('setUser', person);
            })
    }
}

export default SessionModule

import LocalStorage from "../../common/utils/local_storage";
import Person from "../models/Person";
import { CACHE_NAME, FAKE_ENDPOINT, TOKEN_KEY } from "../../common/config/entrypoint";
import fetch from "../../common/utils/fetch";

class UserService {
    private localStorage: LocalStorage;

    constructor() {
        this.localStorage = new LocalStorage();
    }

    getCurrentUser(): Person {
        return this.localStorage.getItem("user");
    }

    getCurrentLanguage(): string {

        return this.localStorage.getItem("language") ?? 'fr';
    }

    async getToken() {
        // const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

        // if (isSafari) {
        //     try {
        //         const options = {
        //             ignoreSearch: true,
        //             ignoreMethod: true,
        //             ignoreVary: true
        //         }
        //         // const cache = await caches.open(CACHE_NAME);
        //         // const response = await cache.match(FAKE_ENDPOINT, options);
        //         const response = await fetch('/token');
        //         console.log(response);
        //
        //         if (!response) {
        //             return "";
        //         }
        //
        //         const responseBody = await response.json();
        //         console.log(responseBody[TOKEN_KEY]);
        //         return responseBody[TOKEN_KEY];
        //     } catch (error) {
        //         // Gotta catch 'em all
        //         console.log("getToken error:", { error });
        //     }
        // }

        return this.localStorage.getItem("token");
    }

    setCurrentLanguage(language: string) {
        this.localStorage.saveItem("language", language);
    }

    setToken(token: string) {
        this.localStorage.saveItem("token", token);
    }

    isGranted(user: Person, roles: Array<string>): boolean {
        return user.roles !== undefined && user.roles.some((role) => roles.includes(role));
    }

    getGuardHouse(): string {
        return this.localStorage.getItem("guard_house");
    }
}

export default UserService;
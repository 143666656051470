



















import { Component, Prop, Vue } from 'vue-property-decorator';
import { MediaApi } from "../../../common/service/api/media";
import PersonalBadgeInterface from "../../interfaces/badge/PersonalBadgeInterface";
import PermanentBadgeInterface from "../../interfaces/badge/PermanentBadgeInterface";
import TemporaryBadgeInterface from "../../interfaces/badge/TemporaryBadgeInterface";
import { namespace } from "vuex-class";

const badge = namespace("Badge");
@Component({
  components: {}
})
export default class BadgeIdentityCard extends Vue {
  private mediaApi: MediaApi = new MediaApi();

  @badge.State
  badge!: PersonalBadgeInterface | PermanentBadgeInterface | TemporaryBadgeInterface;

  identityDocument() {
    if (!this.badge || !this.badge.identityDocument || !("@id" in this.badge.identityDocument)) {
      return '';
    }

    return this.mediaApi.getDownloadUri(this.badge.identityDocument["@id"]);
  }
}

import PersonalBadge from "./PersonalBadge";
import { EnumerationInterface } from "../../interfaces/EnumerationInterface";
import TemporaryBadgeInterface from "../../interfaces/badge/TemporaryBadgeInterface";
import BadgeOrganizationInterface from '../../interfaces/BadgeOrganizationInterface';

export class TemporaryBadge extends PersonalBadge implements TemporaryBadgeInterface {
    counter: number;
    enterprise: BadgeOrganizationInterface;
    visitReason: EnumerationInterface;

    constructor(data: TemporaryBadgeInterface) {
        super(data);
        this.counter = data.counter;
        this.enterprise = data.enterprise;
        this.visitReason = data.visitReason;
    }
}

import VehicleInterface from "../../interfaces/vehicle/VehicleInterface";
import { EnumerationInterface } from "../../interfaces/EnumerationInterface";

export default class Vehicle implements VehicleInterface {
    color: string | null;
    model: string | null;
    registration: string | null;
    type?: EnumerationInterface | null;

    constructor(data: VehicleInterface) {
        this.color = data.color;
        this.model = data.model;
        this.registration = data.registration;
        this.type = data.type;
    }
}
import { Component, Vue } from "vue-property-decorator";
import { PaginatedHydraResponseInterface } from "../../interfaces/response/PaginatedHydraResponseInterface";
import BadgeOrganization from "../../models/BadgeOrganization";
import BadgeOrganizationInterface from "../../interfaces/BadgeOrganizationInterface";
import BadgeOrganizationApi from "../../../common/service/api/badge_organization";
import OrganizationFilterInterface from "../../interfaces/organization/OrganizationFilterInterface";


@Component({
    components: {}
})
export class BadgeOrganizationMixin extends Vue {
    badgeOrganizations: Array<BadgeOrganization> = [];
    badgeOrganizationApi: BadgeOrganizationApi = new BadgeOrganizationApi();
    badgeOrganizationController: AbortController | null = null;

    getBadgeOrganizations(filters: OrganizationFilterInterface): Promise<any> {
        if (this.badgeOrganizationController !== null) {
            this.badgeOrganizationController.abort();
        }

        this.badgeOrganizationController = new AbortController();
        const signal = this.badgeOrganizationController.signal;

        return this.badgeOrganizationApi.findAll({ params: filters, signal })
            .then((response: any) => response.json())
            .then((response: PaginatedHydraResponseInterface) => {
                this.badgeOrganizations = response["hydra:member"].map((badgeOrganization: BadgeOrganizationInterface) => new BadgeOrganization(badgeOrganization));
            })
            .catch((error: any) => {
                if (error.name !== 'AbortError') {
                    this.badgeOrganizations = [];
                }
            })
            ;
    }
}
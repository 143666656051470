import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import BadgeRouter from './badge';
import LandingRoutes from './home';

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
    BadgeRouter,
    LandingRoutes,
]

const router = new VueRouter({
    mode: 'history',
    base: '/app/',
    routes
})

export default router

import ListMixin from "../list";
import { Component } from "vue-property-decorator";
import BadgeView from "../../models/badge/BadgeView";
import BadgeViewApi from "../../../common/service/api/badge_view";
import { PaginatedHydraResponseInterface } from "../../interfaces/response/PaginatedHydraResponseInterface";
import BadgeViewInterface from "../../interfaces/badge/BadgeViewInterface";
import BadgeViewFilterInterface from "../../interfaces/badge/BadgeViewFilterInterface";

@Component({
    components: {}
})
export default class BadgeListMixin extends ListMixin {
    items: Array<BadgeView> = [];
    badgeViewApi: BadgeViewApi = new BadgeViewApi();
    badgeViewController: AbortController | null = null;

    getPage(filters: BadgeViewFilterInterface): Promise<any> {
        if (this.badgeViewController !== null) {
            this.badgeViewController.abort();
        }

        this.badgeViewController = new AbortController();
        const signal = this.badgeViewController.signal;
        return this.badgeViewApi.findAll({ params: filters, signal: signal })
            .then((response: any) => response.json())
            .then((response: PaginatedHydraResponseInterface) => {
                this.options.totalItems = response["hydra:totalItems"];
                this.items = response["hydra:member"].map((badge: BadgeViewInterface) => new BadgeView(badge));
            })
        ;
    }
}
import { ActionInterface } from "../interfaces/ActionInterface";
import { ActionableInterface } from "../interfaces/ActionableInterface";
import { Component, Vue } from "vue-property-decorator";
@Component({
    components: {}
})
export class ActionableMixin extends Vue {
    isActionAllowed(object: ActionableInterface, actionName: string): boolean {
        if (object.actions === undefined) {
            return false;
        }

        return object.actions.indexOf(actionName) > -1;
    }
}
import PermanentBadgeInterface from "../../interfaces/badge/PermanentBadgeInterface";
import PersonalBadge from "./PersonalBadge";
import VehicleInterface from "../../interfaces/vehicle/VehicleInterface";

export class PermanentBadge extends PersonalBadge implements PermanentBadgeInterface {
    vehicles: Array<VehicleInterface>;

    constructor(data: PermanentBadgeInterface) {
        super(data);
        this.vehicles = data.vehicles;
    }
}